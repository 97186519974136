/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    TabbedForm,
    NumberInput,
    SelectInput,
    DateInput,
    DisabledInput,
    ReferenceInput,
    required,
} from 'react-admin';

import Aside from './Aside';

const StockInCreate = function({ permissions, ...props }) {
    const urlParams = new URLSearchParams(props.location.search);
    const redirect = `/stockins/${urlParams.get('request_id')}`;
    
    return (
    <Create aside={<Aside request_id={urlParams.get('request_id')} startbox={urlParams.get('startbox')} endbox={urlParams.get('endbox')} qtyInBox={urlParams.get('qtyInBox')} qtyInTrailBox={urlParams.get('qtyInTrailBox')} />} {...props}>
        <TabbedForm redirect={redirect}>
            <FormTab label="resources.stockins.form.summary" path="">
                
                <NumberInput
                    source="qty"
                    validate={required()}
                />
                <NumberInput
                    source="box"
                    validate={required()}
                />
                <DateInput
                    source="stockInDate"
                    label="resources.stockins.fields.stockInDate"
                    defaultValue={new Date()}
                />
                <SelectInput source="warehouse" choices={[
                    { id: 'rs', name: 'RS' },
                ]} defaultValue='rs' validate={required()} />

                <ReferenceInput source="productId" reference="products" perPage={100} validate={required()} label="resources.stockins.fields.product" defaultValue={parseInt(urlParams.get('product_id'))}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <DateInput
                    source="ExpiryDate"
                    label="resources.stockins.fields.ExpiryDate"
                    defaultValue={new Date()}
                />
                <ReferenceInput source="stockInRequestId" reference="stockinrequests" label="resources.stockins.fields.stock_in_request" validate={required()} defaultValue={parseInt(urlParams.get('request_id'))}>
                    <DisabledInput source="id" />
                </ReferenceInput>
                <ReferenceInput source="appUserId" reference="appusers" validate={required()} label="resources.stockins.fields.user" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>
            </FormTab>
        </TabbedForm>
    </Create>
    )
};

export default StockInCreate;
