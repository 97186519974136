/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    SaveButton,
    Toolbar,
    FormTab,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
    TabbedForm,
    TextInput,
    FileInput,
    FileField,
    LongTextInput,
    required,
} from 'react-admin';

import Aside from './Aside';
import CurrentContractField from './CurrentContractField';
import CurrentBarcodeField from './CurrentBarcodeField';
import PriceInputs_import from './PriceInputs';
import PriceInputsNoProduct_import from './PriceInputsNoProduct';
import autoUpdatePrice from './autoUpdatePrice';
import {PurchaseCategoryOptions,currencyOptions} from '../common/options';

const getAccountInfo = (record) => {
    if(record.name) {
        return record.code + " " + record.name
    } else {
        return record.code
    }
}
const PriceInputs = function({ record }) { 
    return (
        record.category === 'factory' ? 
            <PriceInputs_import />
            : <PriceInputsNoProduct_import />
    );
}
const ProductField = function({ record }) { 
    return (
        record.productId ? 
                    <ReferenceInput 
                        source="productId" 
                        reference="products" 
                        perPage={100} 
                        label="resources.purchases.fields.product" 
                        validate={required()} 
                    >
                        <SelectInput optionText="name" disabled />
                    </ReferenceInput>
                    : ""
    );
}

const PackageField = function({ record }) { 
    return (
        record.packageId ? 
                <ReferenceInput 
                    source="packageId" 
                    reference="packages" 
                    perPage={100} 
                    label="resources.purchases.fields.package" 
                    validate={required()} 
                >
                    <SelectInput optionText="name" disabled />
                </ReferenceInput>
                : ""
    );
}

const VendorField = function({ record }) { 
    return (
                <ReferenceInput 
                    source="vendorId" 
                    reference="vendor" 
                    perPage={1000}
                    sort={{ field: 'name', order: 'ASC' }}
                    label="resources.purchases.fields.vendor" 
                    validate={required()} 
                >
                    <SelectInput optionText="name" disabled />
                </ReferenceInput>
    );
}

const PurchaseToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);
const Fnsku = function({ record }) { 
    return (
        record.category === 'factory' ? 
            <TextInput
                source="fnsku"
                defaultValue=""
            /> : ""
    );
}

const NeiyeField = function({ record }) { 
    return (
        record.category === 'factory' ? 
        <SelectInput source="neiye"  choices={[
            { id: '', name: '' },
            { id: 'Yes', name: 'Yes' },
            { id: 'No', name: 'No' },
        ]} />
                : ""
    );
}
const PurchaseEdit = function({ permissions, ...props }) {
    return (
    <Edit aside={<Aside />} {...props}>
        <TabbedForm onChange={autoUpdatePrice} toolbar={<PurchaseToolbar permissions={permissions} />}>
            <FormTab label="resources.purchases.form.summary" path="">

               <TextInput
                    source="id"
                    format={id => id ? 'OR' + id.toString().padStart(4, '0') : ""} 
                    parse={id => id ? Number(id.substring(2)) : ""}
                    disabled
                />
                <SelectInput source="category"  choices={PurchaseCategoryOptions}  disabled />
                <ProductField />
                <PackageField />
                <VendorField />

                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                />
                <Fnsku />

                <FileInput source="barcode" label="resources.purchases.fields.barcode" multiple>
                    <FileField source="src" title="title" target="_blank" />
                </FileInput>

                <CurrentBarcodeField />

                <FileInput source="contract" label="resources.purchases.fields.contract" multiple  validate={required()}>
                    <FileField source="src" title="title" target="_blank" />
                </FileInput>
  
                <CurrentContractField />
                
                
                <PriceInputs />

                <ReferenceInput source="appUserId" reference="appusers" label="resources.purchases.fields.user" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>

                <ReferenceInput source="accountId" reference="accounts" label="resources.purchases.fields.account" validate={required()} perPage={100} filterToQuery={searchText => ({code: {"like": `%${searchText}%`, "options": "i"}})} filter={{"status": "active"}}>
                    <AutocompleteInput optionText={getAccountInfo} />
                </ReferenceInput>

                <SelectInput source="transport"  choices={[
                    { id: '', name: '' },
                    { id: 'sea', name: 'resources.purchases.transport.sea' },
                    { id: 'air', name: 'resources.purchases.transport.air' },
                    { id: 'other', name: 'resources.purchases.transport.other' },
                ]}
                />

                <FormDataConsumer>
                     {({ formData, ...rest }) => formData.transport === 'other' &&
                          <TextInput source="other_transport" validate={required()} {...rest} />
                     }
                </FormDataConsumer>

                <NeiyeField />
                <TextInput source="created" disabled />
                <LongTextInput
                    source="note"
                />

            </FormTab>

        </TabbedForm>
    </Edit>
    )
};

PurchaseEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default PurchaseEdit;
