// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
import {loobackApiUrl} from './config';

function sendFile(file, url) {

    return new Promise(function(resolve, reject) {

        var xhr = new XMLHttpRequest();
        var fd = new FormData();

        xhr.open("POST", url, true);
        xhr.onreadystatechange = function() {
            if(xhr.readyState === 4 && xhr.status === 200) {
                resolve(JSON.parse(xhr.responseText));
            }
        };

        var d = new Date();
        var n = d.getFullYear().toString() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate().toString()).slice(-2) + ('0' + d.getHours()).slice(-2) + ('0' + d.getMinutes()).slice(-2);
        var type = file.name.split('.').pop();
        var origin_name = file.name.substring(0, file.name.length - type.length - 1);
        // var userId = JSON.parse(localStorage.getItem('lbtoken')).value.userId;

        fd.append('file', file, `${origin_name}-${n}.${type}`);
        xhr.send(fd);

    });
}

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => (type, resource, params) => {

    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'products') {
        // only allow one image upload
        if (params.data.image && params.data.image instanceof Object) {

            return sendFile(params.data.image.rawFile, loobackApiUrl + '/containers/rswarehouse-products/upload')
                .then(function(result) {
                    if(result.result.files && result.result.files.file[0].container) {
                        var location = result.result.files.file[0].providerResponse.location;

                        return requestHandler(type, resource, {
                            ...params,
                            data: {
                                ...params.data,
                                image: location,
                            },
                        });
                    }
                });
        }
    }
    
    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'vendor') {
         // only allow one image upload
         if (params.data.QRCode && params.data.QRCode instanceof Object) {

            return sendFile(params.data.QRCode.rawFile, loobackApiUrl + '/containers/rswarehouse-vendors/upload')
                .then(function(result) {
                    if(result.result.files && result.result.files.file[0].container) {
                        var location = result.result.files.file[0].providerResponse.location;

                        return requestHandler(type, resource, {
                            ...params,
                            data: {
                                ...params.data,
                                QRCode: location,
                            },
                        });
                    }
                });
        }
    }
    

    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'purchases') {
        let promises = [];

        if (params.data.contract && typeof params.data.contract === 'object') {

            if(typeof params.data.contract[0] === 'string') {
                params.data.contract.shift();
            }

            params.data.contract.forEach(function(newContract) {
                promises.push(sendFile(newContract.rawFile, loobackApiUrl + '/containers/rswarehouse-contracts/upload'));
            })
        }

        if (params.data.barcode && typeof params.data.barcode === 'object') {

            if(typeof params.data.barcode[0] === 'string') {
                params.data.barcode.shift();
            }

            params.data.barcode.forEach(function(newBarcode) {
                promises.push(sendFile(newBarcode.rawFile, loobackApiUrl + '/containers/rswarehouse-barcodes/upload'));
            })
        }

        return Promise.all(promises).then(function(results) {
            if(promises.length >= 1) {
                let contract = [];
                let barcode = [];

                results.forEach(function(resultOb) {
                    if(resultOb.result.files && resultOb.result.files.file[0].container) {
                        if(resultOb.result.files.file[0].container === "rswarehouse-contracts") {
                            contract.push(resultOb.result.files.file[0].providerResponse.location);
                        } else if(resultOb.result.files.file[0].container === "rswarehouse-barcodes") {
                            barcode.push(resultOb.result.files.file[0].providerResponse.location);
                        }
                    }
                })

                let newParams = {};
                if(contract.length > 0) {
                    newParams.contract = contract;
                }

                if(barcode.length > 0) {
                   newParams.barcode = barcode; 
                }

                return requestHandler(type, resource, { 
                        ...params,
                        data: {
                            ...params.data,
                            ...newParams
                        },
                });
            } else {
                return requestHandler(type, resource, params);
            }

        })
    }

    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'stockoutrequests') {

        let promises = [];

        if (params.data.boxLabel && typeof params.data.boxLabel === 'object') {

            if(typeof params.data.boxLabel[0] === 'string') {
                params.data.boxLabel.shift();
            }

            params.data.boxLabel.forEach(function(newBoxLabel) {
                promises.push(sendFile(newBoxLabel.rawFile, loobackApiUrl + '/containers/rswarehouse-boxlabels/upload'));
            })
        }
        if (params.data.skulist && typeof params.data.skulist === 'object') {
            promises.push(sendFile(params.data.skulist.rawFile, loobackApiUrl + '/containers/rswarehouse-stockoutskulist/upload'));
        }

        if (params.data.innerPage && typeof params.data.innerPage === 'object') {
            promises.push(sendFile(params.data.innerPage.rawFile, loobackApiUrl + '/containers/rswarehouse-innerpages2/upload'));
        }

        if(params.data.images && typeof params.data.images === 'object') {
            if(typeof params.data.images[0] === 'string') {
                params.data.images.shift();
            }

            params.data.images.forEach(function(newImage) {
                promises.push(sendFile(newImage.rawFile, loobackApiUrl + '/containers/rswarehouse-innerpages/upload'));
            })
        }

        return Promise.all(promises).then(function(results) {
            if(promises.length >= 1) {
                let boxLabel_location = [];
                var skulist_location = "";
                var innerPage_location = "";
                var images = [];

                results.forEach(function(resultOb) {
                    if(resultOb.result.files && resultOb.result.files.file[0].container) {
                        if(resultOb.result.files.file[0].container === "rswarehouse-boxlabels") {
                            boxLabel_location.push(resultOb.result.files.file[0].providerResponse.location);
                        } else if(resultOb.result.files.file[0].container === "rswarehouse-innerpages2") {
                            innerPage_location = resultOb.result.files.file[0].providerResponse.location;
                        } else if(resultOb.result.files.file[0].container === "rswarehouse-innerpages") {
                            images.push(resultOb.result.files.file[0].providerResponse.location);
                        } else if(resultOb.result.files.file[0].container === "rswarehouse-stockoutskulist") {
                            skulist_location = resultOb.result.files.file[0].providerResponse.location;
                        }
                    }
                })

                var newParams = {};


                if(boxLabel_location.length > 0) {
                    newParams.boxLabel = boxLabel_location
                }
                if(innerPage_location !== "") {
                    newParams.innerPage = innerPage_location
                }
                if(skulist_location !== "") {
                    newParams.skulist = skulist_location
                }
                if(images.length > 0) {
                    newParams.images = images
                }

                return requestHandler(type, resource, { 
                        ...params,
                        data: {
                            ...params.data,
                            ...newParams
                        },
                });

            } else {
                return requestHandler(type, resource, params);
            }

        })

    }

    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);
};

export default addUploadFeature;
