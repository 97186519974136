/* eslint react/jsx-key: off */
import memoize from 'lodash/memoize';
import ProductReferenceField from '../products/ProductReferenceField';

import React from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    Responsive,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    SimpleList,
    TextField,
    ReferenceField,
    DateField,
    CardActions,
    RefreshButton,
    ExportButton,
    downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = data => {
    let d = new Date();
    let n = d.getFullYear().toString() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate().toString()).slice(-2) + ('0' + d.getHours()).slice(-2) + ('0' + d.getMinutes()).slice(-2);

    const csv = convertToCSV({data})
    const BOM = '\uFEFF'
    downloadCSV(`${BOM} ${csv}`, 'stockouts-'+n)
}

const StockOutActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>
        <RefreshButton />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </CardActions>
);

const StockOutFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <ReferenceInput source="productId" reference="products" label="resources.stockouts.fields.product" alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>
        <ReferenceInput source="appUserId" reference="appusers" label="resources.stockouts.fields.user" alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.username}`
                }
            />
        </ReferenceInput>
        <SelectInput source="warehouse" alwaysOn choices={[
                    { id: 'rs', name: 'RS' },
                ]}
        />
    </Filter>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'WM'
        ? Promise.resolve('show')
        : Promise.resolve('show');
});

const StockOutList = ({ classes, permissions, ...props }) => (
    <List
        {...props}
        filters={<StockOutFilter permissions={permissions} />}
        sort={{ field: 'stockOutDate', order: 'DESC' }}
        actions={<StockOutActions />}
        exporter={exporter}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record =>
                        permissions === 'admin' ? record.role : null
                    }
                />
            }
            medium={
                <Datagrid
                    rowClick={rowClick(permissions)}
                >
                    <TextField source="id" />
                    <ProductReferenceField label="resources.stockouts.fields.product" />
                    <TextField source="qty" />
                    <TextField source="warehouse" />
                    <DateField source="stockOutDate" options={{timeZone: "UTC"}} />
                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockouts.fields.user">
                        <TextField source="username" />
                    </ReferenceField>
                    <ReferenceField source="stockOutRequestId" reference="stockoutrequests" label="resources.stockouts.fields.stock_out_request">
                        <TextField source="id" />
                    </ReferenceField>
                    <ReferenceField source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" allowEmpty={true} linkType={false}>
                                        <TextField source="code" />
                                    </ReferenceField>
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
);


export default StockOutList;
