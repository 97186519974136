/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    LongTextInput,
    SelectInput,
    FileInput,
    FileField,
    FormDataConsumer,
    ReferenceInput,
    AutocompleteInput,
    Toolbar,
    required,
} from 'react-admin';

import PriceInputs from './PriceInputs';
import PriceInputsNoProduct from './PriceInputsNoProduct';
import autoUpdatePrice from './autoUpdatePrice';
import {PurchaseCategoryOptions,currencyOptions} from '../common/options';

const PurchaseCreateToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const getAccountInfo = (record) => {
    if(record.name) {
        return record.code + " " + record.name
    } else {
        return record.code
    }
}

const PurchaseCreate = function({ permissions, ...props }) {
    const urlParams = new URLSearchParams(props.location.search);
    const productId = urlParams.get('product_id') ? parseInt(urlParams.get('product_id')) : '';
    const packageId = urlParams.get('package_id') ? parseInt(urlParams.get('package_id')) : '';
    const vendorId = urlParams.get('vendor_id') ? parseInt(urlParams.get('vendor_id')) : '';
    const category = urlParams.get('category') ? urlParams.get('category') : '';

    const handleChange = (event, value) => {
        window.location = '#/purchases/create?category='+value;
        window.location.reload();
    };
    return (
    <Create {...props}>
        <TabbedForm toolbar={<PurchaseCreateToolbar permissions={permissions} />} onChange={autoUpdatePrice}>
            <FormTab label="resources.purchases.form.summary" path="">
            { vendorId && category && productId ? (
                <SelectInput source="category"  choices={PurchaseCategoryOptions} defaultValue={category} disabled />
            ): <SelectInput source="category"  choices={PurchaseCategoryOptions} defaultValue={category} onChange={handleChange}/>
            }
                <ReferenceInput 
                    source="productId" 
                    reference="products" 
                    perPage={100} 
                    label="resources.purchases.fields.product" 
                    validate={required()} 
                    defaultValue={productId}
                >
                    <SelectInput optionText="name"  />
                </ReferenceInput>
                

                { packageId && 
                <ReferenceInput 
                    source="packageId" 
                    reference="packages" 
                    perPage={100} 
                    label="resources.purchases.fields.package" 
                    validate={required()} 
                    defaultValue={packageId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                }


                <ReferenceInput 
                    source="vendorId" 
                    reference="vendor"
                    filter={{ category: category }}
                    perPage={1000} 
                    sort={{ field: 'name', order: 'ASC' }}
                    label="resources.purchases.fields.vendor" 
                    validate={required()} 
                    defaultValue={vendorId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                />
                {category === 'factory' && (
                <TextInput
                    source="fnsku"
                    defaultValue=""
                />
                )}
                <FileInput source="barcode" label="resources.purchases.fields.barcode" multiple>
                    <FileField source="src" title="title" target="_blank" />
                </FileInput>
                <FileInput source="contract" label="resources.purchases.fields.contract" multiple  validate={required()}>
                    <FileField source="src" title="title" target="_blank" />
                </FileInput>
                {category === 'factory' && (
                <PriceInputs />
                )}

                {category !== 'factory' && (
                <PriceInputsNoProduct />
                )}

                {category === 'factory' && (
                <ReferenceInput source="accountId" reference="accounts" label="resources.purchases.fields.account"  validate={required()}  perPage={100} filterToQuery={searchText => ({code: {"like": `%${searchText}%`, "options": "i"}})} filter={{"status": "active"}}>
                    <AutocompleteInput optionText={getAccountInfo} />
                </ReferenceInput>
                )}
                {category === 'factory' && (
                <SelectInput source="neiye" validate={required()} choices={[
                    { id: '', name: '' },
                    { id: 'Yes', name: 'Yes' },
                    { id: 'No', name: 'No' },
                ]} />
                )}
                {(category === 'factory' || category === 'shipping' || category === 'package') && (
                <SelectInput source="transport" validate={required()} choices={[
                    { id: '', name: '' },
                    { id: 'sea', name: 'resources.purchases.transport.sea' },
                    { id: 'air', name: 'resources.purchases.transport.air' },
                    { id: 'other', name: 'resources.purchases.transport.other' },
                ]}
                />
                )}
                <FormDataConsumer>
                     {({ formData, ...rest }) => formData.transport === 'other' &&
                          <TextInput source="other_transport" validate={required()} {...rest} />
                     }
                </FormDataConsumer>

                

                <LongTextInput
                    source="note"
                />
                <ReferenceInput source="appUserId" reference="appusers" label="resources.purchases.fields.user" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>
            </FormTab>

        </TabbedForm>
    </Create>
    )
};

export default PurchaseCreate;
