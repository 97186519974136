import React from 'react';

const TransportField = function({ record = {} }) { 
 
	const result = record.transport === "other" ? record.other_transport : record.transport;

    return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
    	{result}
    </div>
    )
};

TransportField.defaultProps = {
    source: 'transport',
    addLabel: false,
};

export default TransportField;