import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class StockInButton extends Component {

   
    handleStockInClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        window.location = '#/stockins/create?product_id='+record.id;  
    }

    render() {
        return (
        <div>
            <a onClick={this.handleStockInClick}  variant="raised">{this.props.translate('resources.products.actions.stock_in')}</a> 
        </div>
        );
    }
}

StockInButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(StockInButton);