/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    NumberInput,
    SelectInput,
    DateInput,
    ReferenceInput,
    Toolbar,
    required,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

const StockInRequestEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="show"
            submitOnEnter={true}
        />
        {permissions === 'admin' && (
            <SaveButton
                label='resources.actions.save_and_next'
                redirect={false}
                submitOnEnter={false}
                variant="flat"
            />
        )}
    </Toolbar>
);

const styles = {
    boxes_start: { display: 'inline-block'},
    boxes_end: { display: 'inline-block', marginLeft: 32 },
};

const StockInRequestCreate = function({ classes,permissions, ...props }) {
 const urlParams = new URLSearchParams(props.location.search);
 const purchaseId = urlParams.get('purchase_id') ? parseInt(urlParams.get('purchase_id')) : '';
 const productId = urlParams.get('product_id') ? parseInt(urlParams.get('product_id')) : '';
 const pm = urlParams.get('pm') ? parseInt(urlParams.get('pm')) : JSON.parse(localStorage.getItem('lbtoken')).value.userId;
 
 return (
    <Create {...props}>
        <TabbedForm toolbar={<StockInRequestEditToolbar permissions={permissions} />}>
            <FormTab label="resources.stockinrequests.form.summary" path="">
                {purchaseId &&
                <ReferenceInput 
                    source="purchaseId" 
                    reference="purchases" 
                    perPage={100} 
                    label="resources.stockinrequests.fields.purchase" 
                    validate={required()} 
                    defaultValue={purchaseId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                }

                {productId &&
                <ReferenceInput 
                    source="productId" 
                    reference="products" 
                    perPage={100} 
                    label="resources.stockinrequests.fields.product" 
                    validate={required()} 
                    defaultValue={productId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                }
                <NumberInput
                    source="qty"
                    validate={required()}
                    label="resources.stockinrequests.record.purchase_qty" 
                    disabled
                />
                <NumberInput
                    source="qtyBatch"
                    validate={required()}
                    label="resources.stockinrequests.record.batch_qty" 
                />
                <SelectInput source="warehouse" choices={[
                    { id: 'rs', name: 'RS' },
                ]} defaultValue="rs" validate={required()} />

                <NumberInput
                    source="boxes_start"
                    validate={required()}
                    formClassName={classes.boxes_start}
                />
                <NumberInput
                    source="boxes_end"
                    validate={required()}
                    formClassName={classes.boxes_end}
                />
                <TextInput
                    source="waybill"
                    validate={required()}
                />
                <SelectInput source="shippingCompany" choices={[
                    { id: 'DHL', name: 'DHL' },
                    { id: 'Fedex', name: 'Fedex' },
                    { id: 'UPS', name: 'UPS' },
                    { id: 'USPS', name: 'USPS' },
                    { id: 'Other', name: 'Other' },
                ]} defaultValue="UPS" validate={required()} />
                <NumberInput
                    source="shippingFee"
                />
                <DateInput
                    source="estArrivalDate"
                    label="resources.stockinrequests.fields.estArrivalDate"
                />
                <NumberInput
                    source="boxes"
                    validate={required()}
                />
                <NumberInput
                    source="qtyInBox"
                    validate={required()}
                />
                <NumberInput
                    source="weightPerBox"
                    validate={required()}
                />
                <TextInput
                    source="box_size"
                    validate={required()}
                />
                <DateInput
                    source="expiryDate"
                    validate={required()}
                />
                <NumberInput
                    source="qtyInTrailBox"
                    validate={required()}
                />

                <SelectInput source="status" choices={[
                    { id: 'stocking_in', name: 'resources.stockinrequests.tab.stocking_in' },
                    { id: 'stocked_in', name: 'resources.stockinrequests.tab.stocked_in' },
                    { id: 'cancelled', name: 'resources.stockinrequests.tab.cancelled' },
                ]} defaultValue="stocking_in" validate={required()} />
                <ReferenceInput source="appUserId" reference="appusers" label="resources.stockinrequests.fields.user" validate={required()} defaultValue={pm}>
                    <SelectInput optionText="username" />
                </ReferenceInput>

            </FormTab>

        </TabbedForm>
    </Create>
)
};

export default withStyles(styles)(StockInRequestCreate);
