import PackageStockOutIcon from '@material-ui/icons/List';
import PackageStockOutList from './PackageStockOutList';
import PackageStockOutCreate from './PackageStockOutCreate';
import PackageStockOutEdit from './PackageStockOutEdit';
import PackageStockOutShow from './PackageStockOutShow';

export default {
    list: PackageStockOutList,
    create: PackageStockOutCreate,
    edit: PackageStockOutEdit,
    show: PackageStockOutShow,
    icon: PackageStockOutIcon,
};
