/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    LongTextInput,
    DateInput,
    SelectInput,
    ReferenceInput,
    Toolbar,
    required,
} from 'react-admin';

import AmountInputs from './AmountInputs';
import autoUpdatePrice from './autoUpdatePrice';
import Aside from './Aside';
import {PurchaseCategoryOptions} from '../common/options';

const PaymentCreateToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const PaymentCreate = function({ permissions, ...props }) {
    const urlParams = new URLSearchParams(props.location.search);
    const purchase_id = urlParams.get('purchase_id') ? parseInt(urlParams.get('purchase_id')) : '';
    const vendor_id = urlParams.get('vendor_id') ? parseInt(urlParams.get('vendor_id')) : '';
    const category = urlParams.get('category') ? urlParams.get('category') : '';

    const total = urlParams.get('total') ? parseFloat(urlParams.get('total')) : '';
    const total_cny = urlParams.get('total_cny') ? parseFloat(urlParams.get('total_cny')) : '';
    const handleChange = (event, value) => {
        window.location = '#/payments/create?category='+value;
        window.location.reload();
    };

    return (
    <Create aside={ purchase_id && <Aside /> } {...props}>
        <TabbedForm toolbar={<PaymentCreateToolbar permissions={permissions} />} onChange={autoUpdatePrice(total, total_cny)}>
            <FormTab label="resources.payments.form.summary" path="">
                { vendor_id && category ? (
                    <SelectInput source="category"  choices={PurchaseCategoryOptions} defaultValue={category} disabled />
                ): <SelectInput source="category"  choices={PurchaseCategoryOptions} defaultValue={category} onChange={handleChange}/>
                }

                <ReferenceInput 
                    source="purchaseId" 
                    reference="purchases" 
                    perPage={100} 
                    label="resources.payments.fields.purchase" 
                    filter={{ category: category }}
                    validate={required()} 
                    defaultValue={purchase_id}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput 
                    source="vendorId" 
                    reference="vendor" 
                    label="resources.payments.fields.vendor" 
                    filter={{ category: category }}
                    validate={required()} 
                    defaultValue={vendor_id}
                    perPage={1000} sort={{ field: 'name', order: 'ASC' }}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <AmountInputs purchaseId={purchase_id} total={total} total_cny={total_cny} />

                <DateInput
                    source="payment_date"
                    label="resources.payments.fields.payment_date"
                    defaultValue={new Date()}
                />

                <SelectInput source="payment_type" choices={[
                    { id: 'full_payment', name: 'Full Payment (全款)' },
                    { id: 'deposit_payment', name: 'Deposit Payment (首款)' },
                    { id: 'balance_payment', name: 'Balance Payment (尾款)' },
                ]} validate={required()} />

                <ReferenceInput source="appUserId" reference="appusers" label="resources.purchases.fields.user" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>
                <DateInput
                    source="product_received_date"
                    label="resources.payments.fields.product_received_date"
                    defaultValue={new Date()}
                />
                <LongTextInput
                    source="note"
                />

            </FormTab>

        </TabbedForm>
    </Create>
    )
};

export default PaymentCreate;
