import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class ShipButton extends Component {
    
    handleShipOrderClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        let vendorId = record.vendorId===null?'':record.vendorId;
        window.location = '#/purchases/create?category=shipping&product_id='+record.productId+'&name='+record.name+'-运输';  
    }

    render() {
        return (
            <div>
                <Button label="Ship" onClick={this.handleShipOrderClick} variant="raised">{this.props.translate('resources.products.actions.shiporder')}</Button>
            </div>
            
        );
    }
}

ShipButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(ShipButton);