/* eslint react/jsx-key: off */
import { withStyles } from '@material-ui/core/styles';
import memoize from 'lodash/memoize';
import PaymentButton from './PaymentButton';
import ShipButton from './ShipButton';
import PurchaseEmbedded from './PurchaseEmbedded';
import ProductReferenceField from './ProductReferenceField';
import PackageReferenceField from './PackageReferenceField';
import PaidField from './PaidField';
import MakeBoxMarkButton from './MakeBoxMarkButton';
import StockInRequestButton from './StockInRequestButton';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import React, { Fragment } from 'react';
import {
    Datagrid,
    EditButton,
    CreateButton,
    Filter,
    List,
    Responsive,
    ReferenceInput,
    AutocompleteInput,
    TextField,
    TextInput,
    NumberField,
    ReferenceField,
    DateField,
    Pagination,
    CardActions,
    RefreshButton,
    ExportButton,
    downloadCSV,
    SelectInput,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import MobileGrid from './MobileGrid';

import DownloadContractField from './DownloadContractField';
import DownloadBarcodeField from './DownloadBarcodeField';
import UploadContractButton from './UploadContractButton';
import PrintedBoxMarkButton from './PrintedBoxMarkButton';
import SentStockInRequestButton from './SentStockInRequestButton';
import MarkCanceledButton from './MarkCanceledButton';
import TransportField from './TransportField';
import {PurchaseCategoryOptions} from '../common/options';
import CreatePurchasesButton from './CreatePurchasesButton';


const exporter = data => {
    let d = new Date();
    let n = d.getFullYear().toString() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate().toString()).slice(-2) + ('0' + d.getHours()).slice(-2) + ('0' + d.getMinutes()).slice(-2);

    const csv = convertToCSV({data})
    const BOM = '\uFEFF'
    downloadCSV(`${BOM} ${csv}`, 'purchases-'+n)
}

const styles = {

}
const PurchaseBulkActionButtons = ({permissions, ...props}) => (

    <Fragment>
        {(permissions === "Admin" || permissions === "PM" || permissions === "PB") &&
        <PrintedBoxMarkButton {...props} />
        }
        {(permissions === "Admin" || permissions === "PM" || permissions === "PB") &&
        <SentStockInRequestButton {...props} />
        }
        {(permissions === "Admin" || permissions === "PM") &&
        <MarkCanceledButton {...props} />
        }
    </Fragment>
);

const PurchaseActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions
}) => (
    <CardActions>
        <CreateButton basePath={basePath}  />
            
        <RefreshButton />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </CardActions>
);

const UnitPriceField = ({ record }) => (
    <span>¥{record.unit_price_cny} / ${record.unit_price}</span>
);

const TotalPriceField = ({ record }) => (
    <span>¥{record.total_price_cny} / ${record.total_price}</span>
);

const PurchaseIdField = ({ record }) => (
    <span><a href={`/#/purchases/${record.id}`} style={{textDecoration: "none"}}>OR{record.id.toString().padStart(4, '0')}</a></span>
);

const PurchasePagination = props => <Pagination rowsPerPageOptions={[20, 40, 60, 100]} {...props} />

const PurchaseFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        
        <SelectInput source="category" alwaysOn choices={PurchaseCategoryOptions}
                resettable
        />
        <TextInput
          label="resources.purchases.fields.id"
          type="search"
          source="pid"
          margin="normal"
          alwaysOn
        />
        <TextInput
          label="resources.purchases.fields.name"
          type="search"
          source="q"
          margin="normal"
          alwaysOn
        />
        <ReferenceInput source="appUserId" reference="appusers" label="resources.products.fields.user" filter={{ realm: "PM" }} sort={{ field: 'username', order: 'ASC' }} alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.username}`
                }
            />
        </ReferenceInput>
    </Filter>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'admin'
        ? Promise.resolve('')
        : Promise.resolve('');
});

const userId = JSON.parse(localStorage.getItem('lbtoken')) ? JSON.parse(localStorage.getItem('lbtoken')).value.userId : "";
class TabbedDatagrid extends React.Component {
    
    state = { pending: [], stocked_in: [] };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }
    
    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
    };
    

    render() {

        const { classes, filterValues, translate, permissions, ...props } = this.props;

        return (

            <Fragment>
                <Tabs
                    fullWidth
                    centered
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    { ['Admin', 'PM', 'PB'].includes(permissions) && 
                        <Tab
                            key="pending"
                            label={translate(`resources.purchases.tab.pending`)}
                            value="pending"
                        />
                    }
                    {/* ['Admin', 'PM', 'PB'].includes(permissions) && 
                        <Tab
                            key="printed_boxmark"
                            label={translate(`resources.purchases.tab.printed_boxmark`)}
                            value="printed_boxmark"
                        />
                */}
                    { ['Admin', 'PM', 'PB'].includes(permissions) && filterValues.category==='factory' &&
                        <Tab
                            key="stocked_in"
                            label={translate(`resources.purchases.tab.stocked_in`)}
                            value="stocked_in"
                        />
                    }
                    { ['Admin', 'PM', 'PB'].includes(permissions) && 
                        <Tab
                            key="canceled"
                            label={translate(`resources.purchases.tab.canceled`)}
                            value="canceled"
                        />
                    }
                </Tabs>
                <Divider />
                <Responsive
                    xsmall={
                        <MobileGrid
                            {...props}
                            ids={this.state[filterValues.status]}
                            userId={userId}
                            permissions={permissions}
                        />
                    }
                    medium={
                        <div>
                            {filterValues.status === 'pending' && (
                                <Datagrid {...props} 
                                    ids={this.state.pending} 
                                    rowClick={rowClick(permissions)}
                                    expand={<PurchaseEmbedded permissions={permissions} />}
                                >
                                    <PurchaseIdField label="resources.purchases.fields.id"/>
                                    { permissions === "Admin" || permissions === "PM" ? <PaymentButton /> : "" }
                                    { filterValues.category==="factory" && (permissions === "Admin" || permissions === "PM") ? <ShipButton /> : "" }
                                    { filterValues.category==="factory" && (permissions === "Admin" || permissions === "PM" || permissions === "PB") ? <StockInRequestButton /> : "" }
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.purchases.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <TextField source="name" />
                                    <DateField source="created" options={{timeZone: "UTC"}}/>
                                    <TextField source="status" />
                                    <NumberField source="qty" />
                                    { filterValues.category==="factory" && (<UnitPriceField label="resources.purchases.fields.unit_price" />)}
                                    <TotalPriceField label="resources.purchases.fields.total_price" />

                                    <PaidField label="resources.purchases.fields.paid" />
                                    <ProductReferenceField label="resources.purchases.fields.product" />
                                    { filterValues.category==="factory" && (<PackageReferenceField label="resources.purchases.fields.package" />)}
                                    { filterValues.category==="factory" && (<TextField source="fnsku" />)}

                                    
                                    { filterValues.category==="factory" && (<ReferenceField source="accountId" reference="accounts" allowEmpty emptyValue="" label="resources.purchases.fields.account">
                                        <TextField source="code" />
                                    </ReferenceField>)}
                                    {/*<TextField source="neiye" />*/}
                                    { (filterValues.category==="factory"|| filterValues.category === 'shipping' || filterValues.category === 'package') && (
                                        <TransportField label="resources.purchases.fields.transport" />
                                    )}
                                    { (filterValues.category==="factory"|| filterValues.category === 'shipping' || filterValues.category === 'package') && (
                                        <DownloadContractField />
                                    )}
                                    {/*<DownloadBarcodeField />*/}
                                    { (filterValues.category==="factory"|| filterValues.category === 'shipping' || filterValues.category === 'package') 
                                        && (permissions === "Admin" || permissions === "PM" || permissions === "PB") 
                                        ? <UploadContractButton /> : "" 
                                    }
                                    <EditButton label="pos.view"/>
                                    
                                    
                                </Datagrid>
                            )}
                            
                            {/*filterValues.status === 'printed_boxmark' && (
                                <Datagrid {...props} 
                                    ids={this.state.printed_boxmark} 
                                    rowClick={rowClick(permissions)}
                                    expand={<PurchaseEmbedded permissions={permissions} />}
                                >
                                    <PurchaseIdField label="resources.purchases.fields.id"/>
                                    <TextField source="name" />
                                    <DateField source="created" options={{timeZone: "UTC"}}/>
                                    <TextField source="status" />
                                    <NumberField source="qty" />
                                    <UnitPriceField label="resources.purchases.fields.unit_price" />
                                    <TotalPriceField label="resources.purchases.fields.total_price" />

                                    <PaidField label="resources.purchases.fields.paid" />
                                    <ProductReferenceField label="resources.purchases.fields.product" />
                                    <PackageReferenceField label="resources.purchases.fields.package" />
                                    <TextField source="fnsku" />

                                    <ReferenceField source="appUserId" reference="appusers" label="resources.purchases.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ReferenceField source="accountId" reference="accounts" allowEmpty emptyValue="" label="resources.purchases.fields.account">
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <TextField source="neiye" />
                                    <TransportField label="resources.purchases.fields.transport" />
                                    <DownloadContractField />
                                    <DownloadBarcodeField />
                                    { userId !== 25 ? <EditButton label="pos.view"/> : "" }
                                    { permissions === "Admin" || permissions === "PM" ? <PaymentButton /> : "" }
                                    { permissions === "Admin" || permissions === "PM" || permissions === "PB" ? <UploadContractButton /> : "" }
                                    { permissions === "Admin" || permissions === "PM" || permissions === "PB" ? <MakeBoxMarkButton /> : "" }
                                    { permissions === "Admin" || permissions === "PM" || permissions === "PB" ? <StockInRequestButton /> : "" }
                                </Datagrid>
                            )*/}
                            {filterValues.status === 'stocked_in' && (
                                <Datagrid {...props} 
                                    ids={this.state.stocked_in} 
                                    rowClick={rowClick(permissions)}
                                    expand={<PurchaseEmbedded permissions={permissions} />}
                                >
                                    <PurchaseIdField label="resources.purchases.fields.id"/>
                                    { permissions === "Admin" || permissions === "PM" ? <PaymentButton /> : "" }
                                    <TextField source="name" />
                                    <DateField source="created" options={{timeZone: "UTC"}}/>
                                    <TextField source="status" />
                                    <NumberField source="qty" />
                                    <UnitPriceField label="resources.purchases.fields.unit_price" />
                                    <TotalPriceField label="resources.purchases.fields.total_price" />

                                    <PaidField label="resources.purchases.fields.paid" />
                                    <ProductReferenceField label="resources.purchases.fields.product" />
                                    <PackageReferenceField label="resources.purchases.fields.package" />
                                    <TextField source="fnsku" />

                                    <ReferenceField source="appUserId" reference="appusers" label="resources.purchases.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ReferenceField source="accountId" reference="accounts" allowEmpty emptyValue="" label="resources.purchases.fields.account">
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <TextField source="neiye" />
                                    <TransportField label="resources.purchases.fields.transport" />
                                    <DownloadContractField />
                                    <DownloadBarcodeField />
                                    <EditButton label="pos.view"/>  
                                    { permissions === "Admin" || permissions === "PM" || permissions === "PB" ? <UploadContractButton /> : "" }
                                    
                                </Datagrid>
                            )}
                            {filterValues.status === 'canceled' && (
                                <Datagrid {...props} 
                                    ids={this.state.canceled} 
                                    rowClick={rowClick(permissions)}
                                    expand={<PurchaseEmbedded permissions={permissions} />}
                                >
                                    <PurchaseIdField label="resources.purchases.fields.id"/>
                                    <TextField source="name" />
                                    <DateField source="created" options={{timeZone: "UTC"}}/>
                                    <TextField source="status" />
                                    <NumberField source="qty" />
                                    { filterValues.category==="factory" && (<UnitPriceField label="resources.purchases.fields.unit_price" />)}
                                    <TotalPriceField label="resources.purchases.fields.total_price" />

                                    <PaidField label="resources.purchases.fields.paid" />
                                    <ProductReferenceField label="resources.purchases.fields.product" />
                                    { filterValues.category==="factory" && (<PackageReferenceField label="resources.purchases.fields.package" />)}
                                    { filterValues.category==="factory" && (<TextField source="fnsku" />)}

                                    <ReferenceField source="appUserId" reference="appusers" label="resources.purchases.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    {/*<TextField source="neiye" />*/}
                                    <TransportField label="resources.purchases.fields.transport" />
                                    <ReferenceField source="accountId" reference="accounts" allowEmpty emptyValue="" label="resources.purchases.fields.account">
                                        <TextField source="name" />
                                    </ReferenceField>
                                    { userId !== 25 ? <EditButton label="pos.view"/> : "" }
                                </Datagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

const PurchaseList = ({ classes, permissions, ...props }) => (
    
    <List
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={20}
        pagination={<PurchasePagination />}
        filters={<PurchaseFilter permissions={permissions} />}
        filterDefaultValues={{ status: 'pending' }}
        actions={<PurchaseActions />}
        bulkActionButtons={<PurchaseBulkActionButtons permissions={permissions} />}
        exporter={exporter}
    >
        <TabbedDatagrid permissions={permissions} />
    </List>
);

export default withStyles(styles)(PurchaseList);