import StockOutIcon from '@material-ui/icons/List';
import StockOutList from './StockOutList';
import StockOutCreate from './StockOutCreate';
import StockOutEdit from './StockOutEdit';
import StockOutShow from './StockOutShow';

export default {
    list: StockOutList,
    create: StockOutCreate,
    edit: StockOutEdit,
    show: StockOutShow,
    icon: StockOutIcon,
};
