import React, { Component }  from 'react';
import compose from 'recompose/compose';
import {translate} from 'react-admin';

class DownloadBoxLabelField extends Component {

	render() {
        const { record } = this.props;
        const boxLabels = typeof record.boxLabel === "string" ? record.boxLabel.split(",") : '';
        const items = [];

        if(boxLabels !== "") {
            for (const value of boxLabels) {
                items.push(<li style={{marginTop:10}}><a href={value} target="_blank" rel="noopener noreferrer">{unescape(decodeURI(value).substr(decodeURI(value).lastIndexOf('/') + 1))}</a></li>)
            }           
        }


	    return (
            <ul style={{listStyle: "disc", padding: 0}}>
            { items }
            </ul>
	    )
	}
};

DownloadBoxLabelField.defaultProps = {
    source: 'boxLabel',
    addLabel: true,
};

const enhance = compose(
    translate
);

export default enhance(DownloadBoxLabelField);