/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    FormTab,
    SelectInput,
    ReferenceInput,
    TabbedForm,
    TextInput,
    NumberInput,
    required,
} from 'react-admin';

import PackageTitle from './PackageTitle';

const PackageEdit = ({ permissions, ...props }) => (
    <Edit title={<PackageTitle />} {...props}>
        <TabbedForm>
            <FormTab label="resources.packages.form.summary" path="">
                <ReferenceInput 
                    source="productId" 
                    reference="products" 
                    perPage={100} 
                    label="resources.packages.fields.product" 
                    validate={required()} 
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput 
                    source="vendorId" 
                    reference="vendor" 
                    perPage={1000} 
                    sort={{ field: 'name', order: 'ASC' }}
                    label="resources.purchases.fields.vendor" 
                    validate={required()} 
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>           
                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                />
                <NumberInput
                    source="qty"
                    defaultValue=""
                    validate={required()}
                />
                <NumberInput
                    source="qtyInBox"
                />
                <NumberInput
                    source="box_weight"
                />
                <TextInput
                    source="box_size"
                    defaultValue=""
                />
                <ReferenceInput source="appUserId" reference="appusers" label="resources.packages.fields.user">
                    <SelectInput optionText="username" />
                </ReferenceInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);

PackageEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default PackageEdit;
