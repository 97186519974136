import * as React from 'react';
import { Query, translate } from 'react-admin';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import RequestButton from './RequestButton';

const Aside = ({ record, basePath, translate }) => {
    return (
        <div style={{ width: 800, margin: '0 0 0 1em' }}>
            <div style={{margin: '0 0 0 1em'}}>
                {record &&<RequestButton record={record}/>}
            </div>
            <div style={{margin: '1em 0 0 1em'}}>
                {record && record.vendorId && <VendorInfo record={record} basePath={basePath} translate={translate} />}
            </div>
            <div style={{margin: '1em 0 0 1em'}}>
                {record && <PurchasesList record={record} basePath={basePath} translate={translate} />}
            </div>
            <div style={{margin: '1em 0 0 1em'}}>
                {record && <StockInRequestList record={record} basePath={basePath} translate={translate} />}
            </div>
            <div style={{margin: '1em 0 0 1em'}}>
                {record && <StockOutRequestList record={record} basePath={basePath} translate={translate} />}
            </div>
        </div>
    );
};
Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
    translate: PropTypes.object,
};

const imgStyle = {
  maxHeight: '5rem',
  position: 'relative',
  margin: '0.5rem',
}
const VendorInfo = ({ record, basePath, translate }) => (
    <Query type="GET_ONE" resource="vendor" payload={{id: record.vendorId}}>
        {({ data, total, loading, error }) => {
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            const redirect = data.id ? `/#/vendor/${data.id}` : "";
            return (
                <Card>
                    <CardHeader
                        title={translate('resources.vendor.form.summary')}
                    />
                    <CardContent>
                       
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.vendor.fields.name')}</TableCell>
                              <TableCell width="75%"><a href={redirect}>{data.name}</a></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.vendor.fields.factory1688Link')}</TableCell>
                              <TableCell width="75%">{data.factory1688Link}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell width="25%">{translate('resources.vendor.fields.paymentMethod')}</TableCell>
                              <TableCell width="75%">{data.paymentMethod}</TableCell>
                            </TableRow>
                            {(data.QRCode!=null ?
                            <TableRow>
                                <TableCell width="25%">{translate('resources.vendor.fields.image')}</TableCell>
                                <TableCell width="75%"><a href={data.QRCode}><img src={data.QRCode} style={imgStyle} alt={data.name} /></a></TableCell>
                              </TableRow>
                              :"")}
                            <TableRow>
                              <TableCell width="25%">{translate('resources.vendor.fields.notes')}</TableCell>
                              <TableCell width="75%">{data.notes}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            );
        }}
    </Query>
  
  )
  const payload = (record) => ({
    pagination: { page: 1, perPage: 20 },
    sort: { field: 'id', order: 'DESC' },
    filter: { productId: record.id }
 });
 const PurchasesList = ({ record, basePath, translate }) => (

  <Query type="GET_LIST" resource="purchases" payload={payload(record)}>
      {({ data, total, loading, error }) => {
          if (data === null) { return <p></p>; }
          if (error) { return <p>ERROR</p>; }
          return (
              <Card>
                  <CardHeader
                      title={translate('resources.purchases.menu')}
                  />
                  <CardContent>
                    <Table aria-label="purchases table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{padding: 0}}>ID</TableCell>
                          <TableCell style={{padding: 0}}>{translate('resources.purchases.fields.name')}</TableCell>
                          <TableCell style={{padding: 0}}>{translate('resources.purchases.fields.qty')}</TableCell>
                          <TableCell style={{padding: 0}}>{translate('resources.purchases.fields.unit_price_cny')}</TableCell>
                          
                          <TableCell style={{padding: 0}}>{translate('resources.purchases.fields.total_price_cny')}</TableCell>  
                          <TableCell style={{padding: 0}}>{translate('resources.purchases.fields.created')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell style={{padding: 0}}><a href={`/#/purchases/${row.id}`}>{row.id}</a></TableCell>
                            <TableCell style={{padding: 0}}>{row.name}</TableCell>
                            <TableCell style={{padding: 0}}>{row.qty}</TableCell>
                            <TableCell style={{padding: 0}}>{row.unit_price_cny}</TableCell>
                            
                            <TableCell style={{padding: 0}}>{row.total_price_cny}</TableCell>
                            <TableCell style={{padding: 0}}>{moment(row.created).format('YYYY/MM/DD')}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      
                    </Table>
                  </CardContent>
              </Card>
          );
      }}
  </Query>
);
  const StockInRequestList = ({ record, basePath, translate }) => (

    <Query type="GET_LIST" resource="stockinrequests" payload={payload(record)}>
        {({ data, total, loading, error }) => {
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            return (
                <Card>
                    <CardHeader
                        title={translate('resources.purchases.record.stock_in_request')}
                    />
                    <CardContent>
                      <Table aria-label="stock_in_request table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{padding: 0}}>ID</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.status')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qty')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyBatch')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyStockedIn')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyPending')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.boxes')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyInBox')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.weightPerBox')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockinrequests.fields.qtyInTrailBox')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell style={{padding: 0}}><a href={`/#/stockinrequests/${row.id}`}>{row.id}</a></TableCell>
                              <TableCell style={{padding: 0}}>{row.status}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qty}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qtyBatch}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qtyBatch - row.qtyPending}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qtyPending}</TableCell>
                              <TableCell style={{padding: 0}}>{row.boxes}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qtyInBox}</TableCell>
                              <TableCell style={{padding: 0}}>{row.weightPerBox}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qtyInTrailBox}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        
                      </Table>
                    </CardContent>
                </Card>
            );
        }}
    </Query>
);

const StockOutRequestList = ({ record, basePath, translate }) => (

    <Query type="GET_LIST" resource="stockoutrequests" payload={payload(record)}>
        {({ data, total, loading, error }) => {
            if (data === null) { return <p></p>; }
            if (error) { return <p>ERROR</p>; }
            return (
                <Card>
                    <CardHeader
                        title={translate('resources.stockoutrequests.menu')}
                    />
                    <CardContent>
                      <Table aria-label="stock_out_request table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{padding: 0}}>ID</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockoutrequests.fields.status')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockoutrequests.fields.qty')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockoutrequests.fields.qtyPending')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockoutrequests.fields.stockOutDate')}</TableCell>
                            <TableCell style={{padding: 0}}>{translate('resources.stockoutrequests.fields.note')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell style={{padding: 0}}><a href={`/#/stockoutrequests/${row.id}`}>{row.id}</a></TableCell>
                              <TableCell style={{padding: 0}}>{row.status}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qty}</TableCell>
                              <TableCell style={{padding: 0}}>{row.qtyPending}</TableCell>
                              <TableCell style={{padding: 0}}>{moment(row.stockOutDate).format('YYYY/MM/DD')}</TableCell>
                              <TableCell width="30%" style={{padding: 0}}>{row.note}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        
                      </Table>
                    </CardContent>
                </Card>
            );
        }}
    </Query>
);
const enhance = compose(
    translate
);

export default enhance(Aside);
