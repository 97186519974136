/* eslint react/jsx-key: off */
import React from 'react';
import { translate } from 'react-admin';

const UserTitle = translate(({ record, translate }) => (
    <span>
        {record ? translate('resources.appusers.edit.title', { title: record.username }) : ''}
    </span>
));

export default UserTitle;
