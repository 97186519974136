/* eslint react/jsx-key: off */
import React, { Component } from 'react';
import { AutocompleteInput, required } from 'react-admin';
import axios from 'axios';
import {loobackApiUrl} from '../config';

class RoleEditField extends Component {
    constructor(props) {
	    super(props);
	    this.state = {role: ""};
	 }

    componentDidMount() {
    	const { record } = this.props;
        axios.get(loobackApiUrl + '/AppUsers/getRole?userId='+record.id).then(function (response) {
            this.setState({
	        	role: response.data.role
	      	});
        }.bind(this));
    }

    render() {
        return (
            <AutocompleteInput
                    source="role"
                    choices={[
                        { id: '', name: 'None' },
                        { id: 'Admin', name: 'Admin' },
                        { id: 'PM', name: 'PM (Product Manager)' },
                        { id: 'WM', name: 'WM (Warehouse Manager)' },
                        { id: 'PB', name: 'PB (Product Buyer)' },
                        { id: 'FM', name: 'FM (Finance Manager)' },
                    ]}
                    validate={required()}
                    defaultValue={this.state.role}
            />
        );
    }
}

export default RoleEditField;
