/* eslint react/jsx-key: off */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class StockInButton2 extends Component {
    
    handleClick = (event) => {
        event.stopPropagation();
        const { record, box, qty } = this.props;
        window.location = '#/stockins/create?request_id='+record.id+'&product_id='+record.productId+'&box='+box+'&qty='+qty+'&qtyInBox='+record.qtyInBox+'&qtyInTrailBox='+record.qtyInTrailBox+'&startbox='+record.boxes_start+'&endbox='+record.boxes_end;    
    }

    render() {
        return (
            <Button label="stock_in" onClick={this.handleClick} color="primary" variant="raised">{this.props.title}</Button>
        );
    }
}

StockInButton2.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(StockInButton2);