/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    LongTextInput,
    NumberInput,
    DateInput,
    SelectInput,
    ReferenceInput,
    Toolbar,
    required,
} from 'react-admin';

import FinanceTypeInputs from './FinanceTypeInputs';

const FinanceCreateToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const FinanceCreate = function({ permissions, ...props }) {
    return (
    <Create {...props}>
        <TabbedForm toolbar={<FinanceCreateToolbar permissions={permissions} />}>
            <FormTab label="resources.finances.form.summary" path="">

                <DateInput
                    source="date"
                    label="resources.finances.fields.date"
                    defaultValue={new Date()}
                />

                <FinanceTypeInputs />

                <NumberInput source="amount" validate={required()} />    



                <ReferenceInput source="appUserId" reference="appusers" label="resources.finances.fields.user" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>

                <LongTextInput
                    source="note"
                />

            </FormTab>

        </TabbedForm>
    </Create>
    )
};

export default FinanceCreate;
