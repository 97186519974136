import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, crudUpdateMany, translate } from 'react-admin';

class MoveToPreparationButton extends Component {
    handleClick = () => {
        const { basePath, crudUpdateMany, resource, selectedIds } = this.props;
        crudUpdateMany(resource, selectedIds, { status: 'preparation' }, basePath);
    };

    render() {
        return (
            <Button label={this.props.translate(`resources.payments.moveto.preparation`)} onClick={this.handleClick} />
        );
    }
}

export default translate(connect(undefined, { crudUpdateMany })(MoveToPreparationButton));