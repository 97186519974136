import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    NumberField,
    TextField,
    EditButton,
    SelectField,
    CardActions,
    CreateButton,
    BulkDeleteButton,
    Filter, TextInput, SelectInput
} from 'react-admin';
import {companyCategoryOptions,currencyOptions} from '../common/options';

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn />
        <TextInput source="phone" alwaysOn />
        <TextInput source="address" alwaysOn />
        <SelectInput source="category"  alwaysOn  choices={companyCategoryOptions}/>
    </Filter>
);

const PostActions = ({basePath}) => (
    <CardActions>
            <CreateButton basePath={basePath}/>
    </CardActions>
);

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);
const MyUrlField = ({ record = {}, source, classes }) =>
    <div>
        {record[source] !== null && record[source] !== '' &&
        <a href={record[source]} >
            1688链接
        </a>
        }
    </div>
//target="_blank"
const rowClick = (id, basePath, record) => {
    return "Products?filter={\"vendorId\":"+id+"}";
}
export const VendorList = ({...props}) => (
    <List {...props}
          title="resources.vendor.menu"
          actions={<PostActions/>}
          bulkActionButtons={<PostBulkActionButtons/>}
          filters={<PostFilter />}
          perPage={25}
    >
        <Datagrid rowClick={rowClick} >
            <NumberField source="id" />
            <TextField source ="name" />
            <TextField source="deliveryDates" style = {{width: 60}}/>
            <SelectField source ="category" choices={companyCategoryOptions} style = {{width: 50}}/>
            <TextField source="phone"/>
            <TextField source="address"/>
            <NumberField source="shippingPrice" style = {{width: 50}}/>
            <TextField source="paymentMethod"/>
            <MyUrlField source="factory1688Link" style={{width:120}}/>
            <TextField  source="notes"/>
            <SelectField source ="currency" choices={currencyOptions}/>
            <EditButton/>
        </Datagrid>
    </List>


);