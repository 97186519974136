import React, { Component }  from 'react';
import compose from 'recompose/compose';
import {translate} from 'react-admin';

class DownloadInnerPageField extends Component {

	handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const { record } = this.props;
        window.open(record.innerPage, '_blank');    
    }

    getInnerPageFile = () => {
        const { record } = this.props;
        if(typeof record.innerPage === 'string') {
            return record.innerPage.substr(record.innerPage.lastIndexOf('.') + 1)
        } else {
            return "";
        }
    }

    render() {
	    return (
	    	this.props.record.innerPage === null ? "" : (typeof this.props.record.innerPage === "string") ? <a href={this.props.record.innerPage} label="download_innerPage" onClick={this.handleClick} color="primary" variant="raised">{this.getInnerPageFile()}</a> : "Uploading"
	    )
	}
};

DownloadInnerPageField.defaultProps = {
    source: 'innerPage',
    addLabel: true,
};

const enhance = compose(
    translate
);

export default enhance(DownloadInnerPageField);