import React, { Component }  from 'react';
import compose from 'recompose/compose';
import {translate} from 'react-admin';
import {apiUrl} from '../config';

class DownloadStockoutLabelField extends Component {

    generateUrl = () => {
        const {record} = this.props;

        return apiUrl+"/makelabel/index.php?auth="+JSON.parse(localStorage.getItem('lbtoken')).value.id+"&id="+record.id+"&productId="+record.productId;
    }

    render() {
	    return (
            <a href={this.generateUrl()} target="_blank" rel="noopener noreferrer">ship label</a>
	    )
	}
};


const enhance = compose(
    translate
);

export default enhance(DownloadStockoutLabelField);