/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    NumberInput,
    SelectInput,
    AutocompleteInput,
    DateInput,
    DisabledInput,
    ReferenceInput,
    Toolbar,
    required,
} from 'react-admin';

const getAccountInfo = (record) => {
    if(record.name) {
        return record.code + " " + record.name
    } else {
        return record.code
    }
}
const StockOutEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="show"
            submitOnEnter={true}
        />
        {permissions === 'admin' && (
            <SaveButton
                label='resources.actions.save_and_next'
                redirect={false}
                submitOnEnter={false}
                variant="flat"
            />
        )}
    </Toolbar>
);


const StockOutCreate = function({ permissions, ...props }) {
    const urlParams = new URLSearchParams(props.location.search);

    return (
    <Create {...props}>
        <TabbedForm toolbar={<StockOutEditToolbar permissions={permissions} />}>
            <FormTab label="resources.stockouts.form.summary" path="">
                
                <NumberInput
                    source="qty"
                    defaultValue={parseInt(urlParams.get('qty'))}
                    validate={required()}
                />
                <DateInput
                    source="stockOutDate"
                    label="resources.stockouts.fields.stockOutDate"
                    defaultValue={new Date()}
                />
                <SelectInput source="warehouse" choices={[
                    { id: 'rs', name: 'RS' },
                ]} defaultValue={urlParams.get('warehouse')} validate={required()} />

                <ReferenceInput source="productId" reference="products" label="resources.stockouts.fields.product" perPage={100} validate={required()} defaultValue={parseInt(urlParams.get('product_id'))}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="stockOutRequestId" reference="stockoutrequests" label="resources.stockouts.fields.stock_out_request" validate={required()} defaultValue={parseInt(urlParams.get('request_id'))}>
                    <DisabledInput source="id" />
                </ReferenceInput>
                <ReferenceInput source="appUserId" reference="appusers" validate={required()} label="resources.stockouts.fields.user" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>
                <ReferenceInput source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" perPage={100} filterToQuery={searchText => ({code: {"like": `%${searchText}%`, "options": "i"}})} filter={{"status": "active"}} sort={{ field: 'id', order: 'ASC' }} defaultValue={parseInt(urlParams.get('account_id'))}>
                    <AutocompleteInput optionText={getAccountInfo} />
                </ReferenceInput>
            </FormTab>
        </TabbedForm>
    </Create>
    )
};

export default StockOutCreate;
