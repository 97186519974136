import React from 'react';

const CurrentBoxLabelField = function({ record = {} }) { 
	
	const boxLabels = typeof record.boxLabel === "string" ? record.boxLabel.split(",") : '';
	const items = [];

	for (const value of boxLabels) {
	    items.push(<li><a href={value} target="_blank" rel="noopener noreferrer">{unescape(decodeURI(value).substr(decodeURI(value).lastIndexOf('/') + 1))}</a></li>)
	}

    return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
    	<ul style={{listStyle: "none", padding: 0}}>
    		{items}
    	</ul>
    </div>
    )
};

CurrentBoxLabelField.defaultProps = {
    source: 'boxLabel',
    addLabel: false,
};

export default CurrentBoxLabelField;