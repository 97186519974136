import React, { Component } from 'react';
import compose from 'recompose/compose';
import InputAdornment from '@material-ui/core/InputAdornment';
import {TextInput, NumberInput, required, translate} from 'react-admin';

import axios from 'axios';
import {loobackApiUrl} from '../config';

class AmountInputs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            percentage: 1,
            total: props.total,
            total_cny: props.total_cny
        }
    }

    componentDidMount() {

        if(this.props.purchaseId) {
          const config = {
            headers: {
              'authorization': JSON.parse(localStorage.getItem('lbtoken')).value.id,
              'Content-Type': 'application/json',
            }
          };

          axios.get(loobackApiUrl + '/Purchases/'+this.props.purchaseId+'/payments', config).then((res) => {

            if(res.status === 200) {
                console.log(res.data);

                if(this.props.total) {
                  let percentage = 1;
                  let total = this.props.total;
                  let total_cny = this.props.total_cny;

                  for(let payment of res.data) {
                      percentage -= payment.percentage;
                      total -= payment.percentage * total;
                      total_cny -= payment.percentage * total_cny;
                  }

                  this.setState({
                      payments: res.data,
                      percentage: percentage,
                      total: total.toFixed(2),
                      total_cny: total_cny.toFixed(2)
                  })
                } else {
                  this.setState({
                      payments: res.data
                  })
                }
            }
          });
        }
    }

    render() {
        return (
            <div>
            <NumberInput id="percentage" label={this.props.translate('resources.payments.fields.percentage')} source="percentage" validate={required()} className="FormInput-input-251" margin="normal"
                defaultValue={this.state.percentage}
                format={v => v*100} parse={v => v/100}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
            />
            <br />
            <TextInput id="amount" label={this.props.translate('resources.payments.fields.amount')} source="amount" style={{marginRight: 20}} validate={required()} margin="normal"
                defaultValue={this.state.total}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
            />
            <TextInput id="amount_cny" label={this.props.translate('resources.payments.fields.amount_cny')} source="amount_cny" validate={required()} margin="normal"
                defaultValue={this.state.total_cny}
                InputProps={{
                    startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                }}
            />
            </div>
        );
    }
}

const enhance = compose(
    translate
);

export default enhance(AmountInputs);