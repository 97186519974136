import React, { Component } from 'react';
import compose from 'recompose/compose';
import InputAdornment from '@material-ui/core/InputAdornment';
import {TextInput, required, translate} from 'react-admin';

class PriceInputsNoProduct extends Component {

    render() {
        return (
            <div>
            <TextInput id="qty" label={this.props.translate('resources.purchases.fields.qty')} source="qty" className="FormInput-input-251" margin="normal"
            />
            <br />
            <TextInput id="total_price" label={this.props.translate('resources.purchases.fields.total_price')} source="total_price" style={{marginRight: 20}} validate={required()} 
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
            />
            <TextInput id="total_price_cny" label={this.props.translate('resources.purchases.fields.total_price_cny')} source="total_price_cny" validate={required()}
                InputProps={{
                    startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                }}
            />
            </div>
        );
    }
}

const enhance = compose(
    translate
);

export default enhance(PriceInputsNoProduct);