/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';

import UserTitle from './UserTitle';
import Aside from './Aside';
import RoleEditField from './RoleEditField';

const UserEdit = ({ permissions, ...props }) => (
    <Edit title={<UserTitle />} aside={<Aside />} {...props}>
            <SimpleForm>
                <TextInput
                    source="username"
                    validate={required()}
                />
                <TextInput
                    source="email"
                    validate={required()}
                />
                <RoleEditField />
            </SimpleForm>
    </Edit>
);

UserEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default UserEdit;
