/* eslint react/jsx-key: off */
import { withStyles } from '@material-ui/core/styles';
import memoize from 'lodash/memoize';
import PackageStockEmbedded from './PackageStockEmbedded';
import RequestButton from './RequestButton';
import QtyInBoxField from '../products/QtyInBoxField';
import ImagePopover from '../products/ImagePopover';

import React, { Fragment } from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    Responsive,
    SimpleList,
    ReferenceInput,
    AutocompleteInput,
    TextField,
    NumberField,
    TextInput,
    ReferenceField,
    Pagination,
    CardActions,
    CreateButton,
    RefreshButton,
    ExportButton,
    downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = data => {
    let d = new Date();
    let n = d.getFullYear().toString() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate().toString()).slice(-2) + ('0' + d.getHours()).slice(-2) + ('0' + d.getMinutes()).slice(-2);

    const csv = convertToCSV({data})
    const BOM = '\uFEFF'
    downloadCSV(`${BOM} ${csv}`, 'packages-'+n)
}

const styles = {
  image: { maxHeight: '3rem' }
}

const PackagePagination = props => <Pagination rowsPerPageOptions={[50, 100, 200, 300]} {...props} />

const PackageBulkActionButtons = ({permissions, ...props}) => (

    <Fragment>

    </Fragment>
);

const PackageActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions
}) => (
    <CardActions>
        { permissions === "Admin" || permissions === "PM" || permissions === "WM" ?
            <CreateButton basePath={basePath} /> : ""
        }
        <RefreshButton />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </CardActions>
);

const PackageFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput
          label="resources.packages.fields.name"
          type="search"
          source="q"
          margin="normal"
          alwaysOn
        />
        <ReferenceInput source="appUserId" reference="appusers" label="resources.packages.fields.user" filter={{ realm: "PM" }} sort={{ field: 'username', order: 'ASC' }} alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.username}`
                }
            />
        </ReferenceInput>
    </Filter>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'admin'
        ? Promise.resolve('edit')
        : Promise.resolve('edit');
});

const PackageList = ({ classes, permissions, ...props }) => (
    <List
        {...props}
        filters={<PackageFilter permissions={permissions} />}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={50}
        pagination={<PackagePagination />}
        actions={<PackageActions permissions={permissions} />}
        bulkActionButtons={<PackageBulkActionButtons permissions={permissions} />}
        exporter={exporter}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.qty }
                />
            }
            medium={
                <Datagrid
                    {...props}
                    rowClick={rowClick(permissions)}
                    expand={<PackageStockEmbedded permissions={permissions} />}
                >
                    <TextField source="id" />
                    <ReferenceField source="productId" reference="products" label="resources.packages.fields.product">
                        <ImagePopover />
                    </ReferenceField>
                    <TextField source="name" />
                    <NumberField source="qty" />
                    <QtyInBoxField />
                    <NumberField source="box_weight" />
                    <NumberField source="box_size" />
                    <ReferenceField source="appUserId" reference="appusers" label="resources.packages.fields.user">
                        <TextField source="username" />
                    </ReferenceField>
                    { permissions === "Admin" || permissions === "PM" || permissions === "PB" || permissions === "WM" ? <EditButton /> : "" }
                    { permissions === "Admin" || permissions === "PM" || permissions === "PB" ? <RequestButton /> : "" }
                </Datagrid>
            }
        />
    </List>
);

export default withStyles(styles)(PackageList);
