/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, SimpleShowLayout, TextField, DateField, ReferenceField } from 'react-admin'; // eslint-disable-line import/no-unresolved

const PercentageField = ({ record = {} }) => <span>{record.percentage}%</span>;
PercentageField.defaultProps = {
    source: 'percentage',
    addLabel: true,
};

const AmountField = ({ record = {} }) => <span>${record.amount}</span>;
AmountField.defaultProps = {
    source: 'amount',
    addLabel: true,
};

const AmountCnyField = ({ record = {} }) => <span>¥{record.amount_cny}</span>;
AmountCnyField.defaultProps = {
    source: 'amount_cny',
    addLabel: true,
};

const PaymentShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="status" />
            <PercentageField />
            <AmountField />
            <AmountCnyField />
            <DateField source="payment_date" options={{timeZone: "UTC"}}/>
            <TextField source="payment_type" />

            
            <ReferenceField label="Purchase" source="purchaseId" reference="purchases">
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField label="User" source="appUserId" reference="appusers">
                <TextField source="username" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);

PaymentShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default PaymentShow;
