/* eslint react/jsx-key: off */
import { withStyles } from '@material-ui/core/styles';
import memoize from 'lodash/memoize';
import ProductRequestEmbedded from './ProductRequestEmbedded';
import RequestButton from './RequestButton';
import StockInButton from './StockInButton';
import PackageButton from './PackageButton';
import ImagePopover from './ImagePopover';
import QtyInBoxField from './QtyInBoxField';

import React, { Fragment } from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    Responsive,
    ReferenceInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    AutocompleteInput,
    SelectInput,
    TextField,
    TextInput,
    DateField,
    ReferenceField,
    Pagination,
    CardActions,
    CreateButton,
    RefreshButton,
    ExportButton,
    downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MobileGrid from './MobileGrid';

import MoveToActiveButton from './MoveToActiveButton';
import MoveToInactiveButton from './MoveToInactiveButton';

const exporter = data => {
    let d = new Date();
    let n = d.getFullYear().toString() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate().toString()).slice(-2) + ('0' + d.getHours()).slice(-2) + ('0' + d.getMinutes()).slice(-2);

    const csv = convertToCSV({data})
    const BOM = '\uFEFF'
    downloadCSV(`${BOM} ${csv}`, 'products-'+n)
}

const styles = {
  image: { maxHeight: '3rem' }
}

const ShelfField = ({ record }) => (
    <b style={{width: "50px", display: "block"}}>{record.shelf}</b>
);

const ProductPagination = props => <Pagination rowsPerPageOptions={[20, 40, 60]} {...props} />

const ProductBulkActionButtons = ({permissions, ...props}) => (

    <Fragment>
        <MoveToActiveButton {...props} />
        <MoveToInactiveButton {...props} />
    </Fragment>
);

const ProductActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions
}) => (
    <CardActions>
        { permissions === "Admin" || permissions === "PM" || permissions === "WM" ?
            <CreateButton basePath={basePath} /> : ""
        }
        <RefreshButton />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </CardActions>
);

const ProductFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput
          label="resources.products.fields.name"
          type="search"
          source="q"
          margin="normal"
          alwaysOn
        />
        <ReferenceInput source="appUserId" reference="appusers" label="resources.products.fields.user" filter={{ realm: "PM" }} sort={{ field: 'username', order: 'ASC' }} alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.username}`
                }
            />
        </ReferenceInput>
        <TextInput
          label="resources.products.fields.shelf"
          type="search"
          source="shelf"
          margin="normal"
          alwaysOn
        />
        <SelectInput source="last_stockout" alwaysOn choices={[
                    { id: 'last one month', name: 'last one month' },
                    { id: 'last two month', name: 'last two month' },
                    { id: 'two month ago', name: 'two month ago' },
                    { id: 'never', name: 'never' },
                ]}
        />
    </Filter>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'admin'
        ? Promise.resolve('edit')
        : Promise.resolve('edit');
});

class TabbedDatagrid extends React.Component {

    state = { active: [], inactive: [] };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
    };

    render() {

        const { classes, filterValues, translate, permissions, ...props } = this.props;

        return (

            <Fragment>
                <Tabs
                    fullWidth
                    centered
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    
                        <Tab
                            key="active"
                            label={translate(`resources.products.tab.active`)}
                            value="active"
                        />
                    
                        <Tab
                            key="inactive"
                            label={translate(`resources.products.tab.inactive`)}
                            value="inactive"
                        />
                </Tabs>
                <Divider />
                <Responsive
                    xsmall={
                        <MobileGrid
                            {...props}
                            ids={this.state[filterValues.status]}
                            permissions={permissions}
                        />
                    }
                    medium={
                        <div>
                            {filterValues.status === 'active' && (
                                <Datagrid
                                    ids={this.state.active} 
                                    {...props}
                                    rowClick={rowClick(permissions)}
                                    expand={<ProductRequestEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    { permissions === "Admin" || permissions === "WM" ? <StockInButton /> : "" }
                                    <ImagePopover />
                                    <TextField source="name" />
                                    <TextField source="boxmark_name" />
                                    <TextField source="brand" />
                                    <TextField source="unitPrice" />
                                    <TextField source="qty" />
                                    <TextField source="qtyInBox" />
                                    {/*<QtyInBoxField />
                                    <TextField source="box_weight" />
                                    <TextField source="box_size" />
                                    <ShelfField label="resources.products.fields.shelf" source="shelf" />
                                    */}
                                    <TextField source="country" />
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.products.fields.user" allowEmpty emptyValue="">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ReferenceManyField label="最近入库数量" reference="stockins" target="productId" sort={{ field: 'id', order: 'DESC' }} perPage={5} >
                                        <SingleFieldList>
                                            <ChipField source="qty" />
                                        </SingleFieldList>
                                    </ReferenceManyField>
                                    <ReferenceManyField label="最近出库数量" reference="stockouts" target="productId" sort={{ field: 'id', order: 'DESC' }} perPage={5} >
                                        <SingleFieldList>
                                            <ChipField source="qty" />
                                        </SingleFieldList>
                                    </ReferenceManyField>
                                    <DateField source="expiryDate" options={{timeZone: "UTC"}} />
                                    { permissions === "Admin" || permissions === "PM" ? <PackageButton source="package" /> : "" }
                                    { permissions === "Admin" || permissions === "PM" || permissions === "WM" ? <RequestButton /> : "" }
                                    { permissions === "Admin" || permissions === "PM" || permissions === "PB" || permissions === "WM" ? <EditButton /> : "" }
                                </Datagrid>
                            )}
                            {filterValues.status === 'inactive' && (
                                <Datagrid
                                    ids={this.state.inactive} 
                                    {...props}
                                    rowClick={rowClick(permissions)}
                                    expand={<ProductRequestEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    <ImagePopover />
                                    <TextField source="name" />
                                    <TextField source="boxmark_name" />
                                    <TextField source="brand" />
                                    
                                    <TextField source="qty" />
                                     {/*<QtyInBoxField />
                                    <TextField source="box_weight" />
                                    <TextField source="box_size" />
                                    <ShelfField label="resources.products.fields.shelf" source="shelf" />
                                     */}
                                    <TextField source="country" />
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.products.fields.user" allowEmpty emptyValue="">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <TextField source="last_stockout" />
                                    { permissions === "Admin" || permissions === "PM" || permissions === "PB" || permissions === "WM" ? <EditButton /> : "" }
                                </Datagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

const ProductList = ({ classes, permissions, ...props }) => (
    <List
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={20}
        pagination={<ProductPagination />}
        filters={<ProductFilter permissions={permissions} />}
        filterDefaultValues={{ status: 'active' }}
        actions={<ProductActions permissions={permissions} />}
        bulkActionButtons={<ProductBulkActionButtons permissions={permissions} />}
        exporter={exporter}
    >
        <TabbedDatagrid permissions={permissions} />
    </List>
);

export default withStyles(styles)(ProductList);
