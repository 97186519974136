/* eslint react/jsx-key: off */
import React, { Component } from 'react';
import { Loading } from 'react-admin';

const imgStyle = {
  maxHeight: '3rem',
  position: 'relative',
  margin: '0.5rem',
}

const popoverStyle = {
	position: 'absolute',
	maxHeight: '30rem',
	zIndex: '999999',
}

class ImagePopover extends Component {
	constructor(props) {
	    super(props)
	    this.state = {
	    	loading: typeof(this.props.record.image) !== "string" && this.props.record.image !== null,
	    	src: this.props.record.image,
	      	hoverShow: false
	    }
	}

 	mouseOver = () => {
    	this.setState({ hoverShow: true })
  	}

  	mouseOut = () => {
    	this.setState({ hoverShow: false })
  	}

	render() {
		return (
	    <span>
	    	{ this.state.loading ? <Loading loadingPrimary="" loadingSecondary="" /> : 
		    	<img src={this.state.src ? this.state.src.replace("s3.amazonaws.com", "s3.us-west-1.amazonaws.com") : ""} onMouseOver={this.mouseOver} onMouseOut={this.mouseOut} style={imgStyle} alt={this.state.src ? this.state.src.substr(this.state.src.lastIndexOf('/') + 1) : "image"}/>
		    }
	    	{ this.state.hoverShow &&
	    		<img src={this.state.src ? this.state.src.replace("s3.amazonaws.com", "s3.us-west-1.amazonaws.com") : ""} style={popoverStyle} alt={this.state.src ? this.state.src.substr(this.state.src.lastIndexOf('/') + 1) : "image"}/>
	    	}
	    </span>
	    )
	}
}

ImagePopover.defaultProps = {
    source: 'image',
    addLabel: true,
};

export default ImagePopover;
