/* eslint react/jsx-key: off */
import React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    SelectInput,
    required,
} from 'react-admin';

const AccountEdit = function({ permissions, ...props }) {

    return (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="resources.accounts.form.summary" path="">
                
                <TextInput
                    source="code"
                    defaultValue=""
                    validate={required()}
                />
                <TextInput
                    source="name"
                    defaultValue=""
                />
                <TextInput
                    source="company"
                    defaultValue=""
                />
                <TextInput
                    source="address"
                    defaultValue=""
                />

                <TextInput
                    source="city"
                    defaultValue=""
                />

                <TextInput
                    source="state"
                    defaultValue=""
                />

                <TextInput
                    source="country"
                    defaultValue=""
                />

                <TextInput
                    source="zipcode"
                    defaultValue=""
                />

                <TextInput
                    source="signature_name"
                    defaultValue=""
                />

                <SelectInput source="status" choices={[
                    { id: 'active', name: 'Active' },
                    { id: 'blocked', name: 'Blocked' },
                ]} />

            </FormTab>
        </TabbedForm>
    </Edit>
    )
};

export default AccountEdit;
