/* eslint react/jsx-key: off */
import ProductIcon from '@material-ui/icons/Storage';
import memoize from 'lodash/memoize';
// import StockInListEmbedded from './StockInListEmbedded';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import compose from 'recompose/compose';
import StockInButton from './StockInButton';
import StockInBoxButton from './StockInBoxButton';
import StockInEmbedded from './StockInEmbedded';
import StatusField from './StatusField';
import WeightPerBoxField from './WeightPerBoxField';

import React, { Fragment } from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    Responsive,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    TextField,
    NumberField,
    ReferenceField,
    FunctionField,
    DateField,
    translate,
    Pagination,
    CardActions,
    RefreshButton,
    ExportButton,
    BulkDeleteButton,
    downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import MobileGrid from '../layout/MobileGrid';

export const UserIcon = ProductIcon;

const exporter = data => {
    let d = new Date();
    let n = d.getFullYear().toString() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate().toString()).slice(-2) + ('0' + d.getHours()).slice(-2) + ('0' + d.getMinutes()).slice(-2);

    const csv = convertToCSV({data})
    const BOM = '\uFEFF'
    downloadCSV(`${BOM} ${csv}`, 'stockinrequests-'+n)
}

const StockInRequestBulkActionButtons = ({permissions, ...props}) => (

    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

const StockInRequestActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>
        <RefreshButton />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </CardActions>
);

const ProductReferenceField = props => (
    <div>
        {props.record.productId ?
            <ReferenceField
                source="productId"
                reference="products"
                allowEmpty emptyValue=""
                {...props}
            >
                <TextField source="name" />
            </ReferenceField> : ""
        }
    </div>
);

const PurchaseReferenceField = props => (
    <div>
        {props.record.purchaseId ?
            <ReferenceField
                source="purchaseId"
                reference="purchases"
                allowEmpty emptyValue=""
                {...props}
            >
                <TextField source="name" />
            </ReferenceField> : ""
        }
    </div>
);

const StockInRequestPagination = props => <Pagination rowsPerPageOptions={[25, 50]} {...props} />

const StockInRequestFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput
          label="resources.purchases.fields.id"
          type="search"
          source="pid"
          margin="normal"
          alwaysOn
        />
        <ReferenceInput source="productId" reference="products" label="resources.stockinrequests.fields.product" alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>
        <ReferenceInput source="appUserId" reference="appusers" label="resources.stockinrequests.fields.user" sort={{ field: 'username', order: 'ASC' }} alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.username}`
                }
            />
        </ReferenceInput>
    </Filter>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'WM'
        ? Promise.resolve('edit')
        : Promise.resolve('edit');
});

class TabbedDatagrid extends React.Component {

    state = { stocking_in: [], stocked_in: [], cancelled: [] };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
    };

    render() {

        const { classes, filterValues, translate, permissions, ...props } = this.props;

        return (

            <Fragment>
                <Tabs
                    fullWidth
                    centered
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                        <Tab
                            key="stocking_in"
                            label={translate(`resources.stockinrequests.tab.stocking_in`)}
                            value="stocking_in"
                        />
                        <Tab
                            key="processing"
                            label={translate(`resources.stockinrequests.tab.processing`)}
                            value="processing"
                        />
                        <Tab
                            key="stocked_in"
                            label={translate(`resources.stockinrequests.tab.stocked_in`)}
                            value="stocked_in"
                        />
                        <Tab
                            key="cancelled"
                            label={translate(`resources.stockinrequests.tab.cancelled`)}
                            value="cancelled"
                        />
                </Tabs>
                <Divider />
                <Responsive
                    xsmall={
                        <MobileGrid
                            {...props}
                            ids={this.state[filterValues.status]}
                        />
                    }
                    medium={
                        <div>
                            {filterValues.status === 'stocking_in' && (
                                <Datagrid {...props} 
                                    ids={this.state.stocking_in} 
                                    rowClick={rowClick(permissions)}
                                    expand={<StockInEmbedded permissions={permissions} />}
                                >
                                    <ReferenceField source="purchaseId" allowEmpty emptyValue="" reference="purchases" label="resources.stockinrequests.fields.purchaseId">
                                        <FunctionField render={record => record ? 'OR'+record.id.toString().padStart(4, '0') : null} />
                                    </ReferenceField>
                                    <ReferenceField source="appUserId" allowEmpty emptyValue="" reference="appusers" label="resources.stockinrequests.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockinrequests.fields.product" />
                                    <PurchaseReferenceField label="resources.stockinrequests.fields.purchase" />
                                    <StatusField />
                                    <NumberField source="boxes" />
                                    <NumberField source="boxes_start" />
                                    <NumberField source="boxes_end" />
                                    <NumberField source="qtyInBox" />
                                    <NumberField source="qtyInTrailBox" />
                                    <WeightPerBoxField />
                                    {/*<NumberField source="qty" />*/}
                                    <NumberField source="qtyBatch" />
                                    <NumberField source="qtyStockedIn" />
                                    <NumberField source="qtyPending" />
                                    <TextField source="warehouse" />
                                    <TextField source="waybill" />
                                    <DateField source="estArrivalDate" options={{timeZone: "UTC"}} />
                                    <TextField source="shippingCompany" />
                                    <NumberField source="shippingFee" />
                                    <EditButton />
                                    {permissions === "Admin" || permissions === 'WM' ? <StockInButton /> : "" }
                                    {permissions === "Admin" || permissions === 'WM' ? <StockInBoxButton /> : "" }
                                </Datagrid>
                            )}
                            {filterValues.status === 'processing' && (
                                <Datagrid {...props} 
                                    ids={this.state.processing} 
                                    rowClick={rowClick(permissions)}
                                    expand={<StockInEmbedded permissions={permissions} />}
                                >
                                    <ReferenceField source="purchaseId" allowEmpty emptyValue="" reference="purchases" label="resources.stockinrequests.fields.purchaseId">
                                        <FunctionField render={record => record ? 'OR'+record.id.toString().padStart(4, '0') : null} />
                                    </ReferenceField>
                                    <ReferenceField source="appUserId" allowEmpty emptyValue="" reference="appusers" label="resources.stockinrequests.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockinrequests.fields.product" />
                                    <PurchaseReferenceField label="resources.stockinrequests.fields.purchase" />
                                    <StatusField />
                                    <NumberField source="boxes" />
                                    <NumberField source="boxes_start" />
                                    <NumberField source="boxes_end" />
                                    <NumberField source="qtyInBox" />
                                    <NumberField source="qtyInTrailBox" />
                                    <WeightPerBoxField />
                                    <NumberField source="qtyBatch" />
                                    <NumberField source="qtyStockedIn" />
                                    <NumberField source="qtyPending" />
                                    <TextField source="warehouse" />
                                    <TextField source="waybill" />
                                    <DateField source="estArrivalDate" options={{timeZone: "UTC"}} />
                                    <TextField source="shippingCompany" />
                                    <NumberField source="shippingFee" />
                                    <EditButton />
                                    {permissions === "Admin" || permissions === 'WM' ? <StockInButton /> : "" }
                                    {permissions === "Admin" || permissions === 'WM' ? <StockInBoxButton /> : "" }
                                </Datagrid>
                            )}
                            {filterValues.status === 'stocked_in' && (
                                <Datagrid {...props} 
                                    ids={this.state.stocked_in} 
                                    rowClick={rowClick(permissions)}
                                    expand={<StockInEmbedded permissions={permissions} />}
                                >
                                    <ReferenceField source="purchaseId" allowEmpty emptyValue="" reference="purchases" label="resources.stockinrequests.fields.purchaseId">
                                        <TextField source="id" />
                                    </ReferenceField>
                                    <ReferenceField source="appUserId" allowEmpty emptyValue="" reference="appusers" label="resources.stockinrequests.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockinrequests.fields.product" />
                                    <PurchaseReferenceField label="resources.stockinrequests.fields.purchase" />
                                    <StatusField />
                                    <NumberField source="boxes" />
                                    <NumberField source="boxes_start" />
                                    <NumberField source="boxes_end" />
                                    <NumberField source="qtyInBox" />
                                    <NumberField source="qtyInTrailBox" />
                                    <WeightPerBoxField />
                                    <NumberField source="qtyBatch" />
                                    <NumberField source="qtyStockedIn" />
                                    <NumberField source="qtyPending" />
                                    <TextField source="warehouse" />
                                    <TextField source="waybill" />
                                    <DateField source="estArrivalDate" options={{timeZone: "UTC"}} />
                                    <TextField source="shippingCompany" />
                                    <NumberField source="shippingFee" />
                                    <EditButton />
                                    {permissions === "Admin" || permissions === 'WM' ? <StockInButton /> : "" }
                                    {permissions === "Admin" || permissions === 'WM' ? <StockInBoxButton /> : "" }
                                </Datagrid>
                            )}
                            {filterValues.status === 'cancelled' && (
                                <Datagrid {...props} 
                                    ids={this.state.cancelled} 
                                    rowClick={rowClick(permissions)}
                                    expand={<StockInEmbedded permissions={permissions} />}
                                >
                                    <ReferenceField source="purchaseId" allowEmpty emptyValue="" reference="purchases" label="resources.stockinrequests.fields.purchaseId">
                                        <TextField source="id" />
                                    </ReferenceField>
                                    <ReferenceField source="appUserId" allowEmpty emptyValue="" reference="appusers" label="resources.stockinrequests.fields.user">
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockinrequests.fields.product" />
                                    <PurchaseReferenceField label="resources.stockinrequests.fields.purchase" />
                                    <StatusField />
                                    <NumberField source="boxes" />
                                    <NumberField source="boxes_start" />
                                    <NumberField source="boxes_end" />
                                    <NumberField source="qtyInBox" />
                                    <NumberField source="qtyInTrailBox" />
                                    <WeightPerBoxField />
                                    <NumberField source="qty" />
                                    <NumberField source="qtyStockedIn" />
                                    <NumberField source="qtyPending" />
                                    <TextField source="warehouse" />
                                    <TextField source="waybill" />
                                    <DateField source="estArrivalDate" options={{timeZone: "UTC"}} />
                                    <TextField source="shippingCompany" />
                                    <NumberField source="shippingFee" />
                                    <EditButton />
                                </Datagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );

    }
}

const StockInRequestList = ({ permissions, ...props }) => (
    <List
        {...props}
        filterDefaultValues={{ status: 'stocking_in' }}
        sort={{ field: 'estArrivalDate', order: 'DESC' }}
        perPage={50}
        pagination={<StockInRequestPagination />}
        actions={<StockInRequestActions />}
        bulkActionButtons={<StockInRequestBulkActionButtons permissions={permissions} />}
        filters={<StockInRequestFilter />}
        exporter={exporter}
    >
        <TabbedDatagrid permissions={permissions} />
    </List>
);

const enhance = compose(
    translate
);

export default enhance(StockInRequestList);
