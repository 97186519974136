/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    Toolbar,
    required,
} from 'react-admin';

const PackageCreateToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const PackageCreate = function({ permissions, ...props }) {
    const urlParams = new URLSearchParams(props.location.search);
    const productId = urlParams.get('product_id') ? parseInt(urlParams.get('product_id')) : '';
    const vendorId = urlParams.get('vendor_id') ? parseInt(urlParams.get('vendor_id')) : '';

    return (
    <Create {...props}>
        <TabbedForm toolbar={<PackageCreateToolbar permissions={permissions} />}>
            <FormTab label="resources.packages.form.summary" path="">
                <ReferenceInput 
                    source="productId" 
                    reference="products" 
                    perPage={100} 
                    label="resources.packages.fields.product" 
                    validate={required()} 
                    defaultValue={productId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput 
                    source="vendorId" 
                    reference="vendor" 
                    perPage={1000} 
                    sort={{ field: 'name', order: 'ASC' }}
                    label="resources.purchases.fields.vendor" 
                    validate={required()} 
                    defaultValue={vendorId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                />
                <NumberInput
                    source="qty"
                    defaultValue=""
                    validate={required()}
                />
                <NumberInput
                    source="qtyInBox"
                />
                <NumberInput
                    source="box_weight"
                />
                <TextInput
                    source="box_size"
                    defaultValue=""
                />
                <ReferenceInput source="appUserId" reference="appusers" label="resources.products.fields.user" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>

            </FormTab>

        </TabbedForm>
    </Create>
    )
};

export default PackageCreate;
