import React from 'react';
import {
    Create,
    SimpleForm,
    NumberInput,
    TextInput,
    SelectInput,
    ImageInput,
    ImageField,
    LongTextInput
} from 'react-admin';

import {companyCategoryOptions,currencyOptions} from '../common/options';

export const VendorCreate = props => (
    <Create {...props} title="resources.vendor.form.summary" >
        <SimpleForm redirect="/vendor">
            <TextInput source ="name" />
            <TextInput source ="deliveryDates" />
            <SelectInput source="category"  choices={companyCategoryOptions}/>
            <TextInput source="phone" />
            <TextInput source="address"/>
            <NumberInput source="shippingPrice" />
            <LongTextInput source="paymentMethod" multiline  />
            <LongTextInput source="factory1688Link"/>
            <ImageInput source="QRCode"  accept="image/*" placeholder={<p>Drop your file here</p>}>
                <ImageField source="QRCode" title="title" />
            </ImageInput>
            <TextInput source="notes" multiline  />
            <SelectInput source="currency"  choices={currencyOptions} />
        </SimpleForm>
    </Create>
);