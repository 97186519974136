import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class RequestButton extends Component {

    handlePurchaseClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        window.location = '#/purchases/create?package_id='+record.id;  
    }

    render() {
        return (
        <div>
            <Button label="purchase" onClick={this.handlePurchaseClick} color="primary" variant="raised">{this.props.translate('resources.products.actions.purchase')}</Button>
        </div>
        );
    }
}

RequestButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(RequestButton);