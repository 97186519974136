import PurchaseIcon from '@material-ui/icons/ShoppingCart';
import PurchaseCreate from './PurchaseCreate';
import PurchaseEdit from './PurchaseEdit';
import PurchaseList from './PurchaseList';
import PurchaseShow from './PurchaseShow';

export default {
    list: PurchaseList,
    create: PurchaseCreate,
    edit: PurchaseEdit,
    show: PurchaseShow,
    icon: PurchaseIcon,
};
