import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import {
    EditButton,
    DateField,
    translate,
    NumberField,
    TextField,
} from 'react-admin';

import PaymentButton from './PaymentButton';
import UploadContractButton from './UploadContractButton';
import MakeBoxMarkButton from './MakeBoxMarkButton';

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: theme.typography.body1,
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
});

const PurchaseIdField = ({ record }) => (
    <span><a href={`/#/purchases/${record.id}`} style={{textDecoration: "none"}}>OR{record.id.toString().padStart(4, '0')}</a></span>
);

const UnitPriceField = ({ record }) => (
    <span>¥{record.unit_price_cny} / ${record.unit_price}</span>
);

const TotalPriceField = ({ record }) => (
    <span>¥{record.total_price_cny} / ${record.total_price}</span>
);

const MobileGrid = ({ classes, ids, data, basePath, translate, permissions, userId }) => (
    <div style={{ margin: '1em' }}>
        {ids.map(id => (
            <Card key={id} className={classes.card}>
                <CardHeader
                    title={
                        <div className={classes.cardTitleContent}>
                            <span>
                                {translate('resources.purchases.fields.name', 1)}:&nbsp;
                                <TextField
                                    record={data[id]}
                                    source="name"
                                />
                            </span>
  
                            { userId !== 25 ? <EditButton label="pos.view" basePath={basePath} record={data[id]}/> : "" }   
                        </div>
                    }
                />
                <CardContent className={classes.cardContent}>
                    <span className={classes.cardContentRow}>
                        {translate('resources.purchases.fields.id')}:&nbsp;
                        <PurchaseIdField label="resources.purchases.fields.id" record={data[id]}/>
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.purchases.fields.qty')}:&nbsp;
                        <NumberField source="qty" record={data[id]} />
                    </span>
                    <span className={classes.cardContentRow}>
                        Created:&nbsp;
                        <DateField source="created" options={{timeZone: "UTC"}} record={data[id]}/>
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.purchases.fields.unit_price')}:&nbsp;
                        <UnitPriceField label="resources.purchases.fields.unit_price" record={data[id]}/>
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.purchases.fields.total_price')}:&nbsp;
                        <TotalPriceField label="resources.purchases.fields.total_price" record={data[id]}/>
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.purchases.fields.fnsku')}:&nbsp;
                        <TextField source="fnsku" record={data[id]}/>
                    </span>
                    <span>&nbsp;</span>

                    <Grid container spacing={1}>
                      <Grid container item xs={4} spacing={1}>
                        { permissions === "PM" ? <PaymentButton record={data[id]}/> : "" }
                      </Grid>
                      <Grid container item xs={4} spacing={1}>
                        { permissions === "PM" || permissions === "PB" ? <UploadContractButton record={data[id]}/> : "" }
                      </Grid>
                      <Grid container item xs={4} spacing={1}>
                        { permissions === "PM" || permissions === "PB" ? <MakeBoxMarkButton record={data[id]}/> : "" } 
                      </Grid>
                    </Grid>
                            
                    
                </CardContent>
            </Card>
        ))}
    </div>
);

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

const enhance = compose(
    withStyles(listStyles),
    translate
);

export default enhance(MobileGrid);