import React from 'react';

const CurrentContractField = function({ record = {} }) { 
	
	const contracts = typeof record.contract === "string" ? record.contract.split(",") : '';
	const items = [];

	for (const value of contracts) {
	    items.push(<li><a href={value} target="_blank" rel="noopener noreferrer">{unescape(decodeURI(value).substr(decodeURI(value).lastIndexOf('/') + 1))}</a></li>)
	}

    return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
    	<ul style={{listStyle: "none", padding: 0}}>
    		{items}
    	</ul>
    </div>
    )
};

CurrentContractField.defaultProps = {
    source: 'contract',
    addLabel: false,
};

export default CurrentContractField;