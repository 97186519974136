import BusinessIcon from '@material-ui/icons/Business';
 import {VendorCreate} from './VendorCreate';
 import {VendorEdit}  from './VendorEdit';
 import {VendorList}  from './VendorList';
//
 export default {
     list: VendorList,
     edit: VendorEdit,
     create: VendorCreate,
     icon: BusinessIcon,
 };
