import AccountIcon from '@material-ui/icons/AccountBalance';
import AccountList from './AccountList';
import AccountCreate from './AccountCreate';
import AccountEdit from './AccountEdit';
import AccountShow from './AccountShow';

export default {
    list: AccountList,
    create: AccountCreate,
    edit: AccountEdit,
    show: AccountShow,
    icon: AccountIcon,
};
