/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, SimpleShowLayout, TextField, DateField, ReferenceField } from 'react-admin'; // eslint-disable-line import/no-unresolved

const AmountField = ({ record = {} }) => <span>¥{record.amount}</span>;
AmountField.defaultProps = {
    source: 'amount',
    addLabel: true,
};

const BalanceField = ({ record = {} }) => <span>¥{record.balance}</span>;
BalanceField.defaultProps = {
    source: 'balance',
    addLabel: true,
};

const FinanceShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />

            <AmountField />
            <DateField source="date" options={{timeZone: "UTC"}}/>
            <TextField source="type" />
            <TextField source="description" />
            <BalanceField />

            <ReferenceField label="User" source="appUserId" reference="appusers">
                <TextField source="username" />
            </ReferenceField>
            <TextField source="note" />
        </SimpleShowLayout>
    </Show>
);

FinanceShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default FinanceShow;
