import React from 'react';
import { ReferenceField } from 'react-admin';
import ProductImageField from './ProductImageField';

const ProductReferenceField = props => (
    <ReferenceField
        label="Product"
        source="productId"
        reference="products"
        allowEmpty emptyValue=""
        {...props}
    >
        <ProductImageField />
    </ReferenceField>
);

ProductReferenceField.defaultProps = {
    source: 'productId',
    addLabel: true,
};

export default ProductReferenceField;