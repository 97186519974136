import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class StockInRequestButton extends Component {
    
    handleProductClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        let boxes = record.boxes===null?'':record.boxes;
        let qtyInBox = record.qtyInBox===null?'':record.qtyInBox;
        let qtyInTrailBox = record.qtyInTrailBox===null?'':record.qtyInTrailBox;
        let weightPerBox = record.weightPerBox===null?'':record.weightPerBox;

        window.location = '#/stockinrequests/create?purchase_id='+record.id+'&pm='+record.appUserId+'&product_id='+record.productId+'&qty='+record.qty+'&boxes='+boxes+'&qtyInBox='+qtyInBox+'&qtyInTrailBox='+qtyInTrailBox+'&weightPerBox='+weightPerBox;  
    }

    handlePackageClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        let boxes = record.boxes===null?'':record.boxes;
        let qtyInBox = record.qtyInBox===null?'':record.qtyInBox;
        let qtyInTrailBox = record.qtyInTrailBox===null?'':record.qtyInTrailBox;
        let weightPerBox = record.weightPerBox===null?'':record.weightPerBox;
        
        window.location = '#/packagestockins/create?purchase_id='+record.id+'&package_id='+record.packageId+'&qty='+record.qty+'&boxes='+boxes+'&qtyInBox='+qtyInBox+'&qtyInTrailBox='+qtyInTrailBox+'&weightPerBox='+weightPerBox;          
    }

    render() {
        return (
        <div>
            {this.props.record.productId &&
            <Button label="stockinrequest" onClick={this.handleProductClick} color="primary" variant="raised">{this.props.translate('resources.purchases.actions.productstockin')}</Button>
            }
            {this.props.record.packageId &&
            <Button label="packagestockin" onClick={this.handlePackageClick} color="primary" variant="raised">{this.props.translate('resources.purchases.actions.packagestockin')}</Button>
            }
        </div>
        );
    }
}

StockInRequestButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(StockInRequestButton);