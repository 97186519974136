/* eslint react/jsx-key: off */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {translate} from 'react-admin';
import axios from 'axios';
import {loobackApiUrl} from '../config';

class PaidField extends Component {
    constructor(props) {
        super(props);
        this.state = {amount: 0, amount_cny: 0, percentage: 0};
    }

    componentDidMount() {
        const { record } = this.props;
        const config = {
          headers: {
            'authorization': JSON.parse(localStorage.getItem('lbtoken')).value.id,
            'Content-Type': 'application/json',
          }
        };

        axios.get(loobackApiUrl + '/Purchases/'+record.id+"/payments", config).then((response) => {
            let percentage = 0;
            let amount = 0;
            let amount_cny = 0;

            
            for(let payment of response.data) {
                if(payment.status !== "canceled") {
                    percentage += payment.percentage;
                    amount += payment.amount;
                    amount_cny += payment.amount_cny;
                }
            }
            

            this.setState({
                percentage: percentage,
                amount: amount,
                amount_cny: amount_cny
            })

        });
    }

    render() {
        return (
            <span>¥{parseFloat(this.state.amount_cny.toFixed(2))} / ${parseFloat(this.state.amount.toFixed(2))} (<b>{this.state.percentage * 100}%</b>)</span>
        );
    }
}

PaidField.propTypes = {
    record: PropTypes.object,
};

const enhance = compose(
    translate
);

export default enhance(PaidField);