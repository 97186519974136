import React from 'react';
import {translate} from 'react-admin';

const statusStyle = {
  minWidth: '40px',
}

const StatusField = translate(({ record, translate }) => (
    <div style={statusStyle}>
        {
            record.status === "stocking_in" ? translate('resources.stockinrequests.tab.stocking_in') : 
            record.status === "processing" ? translate('resources.stockinrequests.tab.processing') : 
            record.status === "stocked_in" ? translate('resources.stockinrequests.tab.stocked_in') : translate('resources.stockoutrequests.tab.cancelled') 
        }
    </div>
));

StatusField.defaultProps = {
    source: 'status',
    addLabel: true,
};

export default StatusField;