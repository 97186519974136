/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    TextInput,
    SelectInput,
    Toolbar,
    required,
} from 'react-admin';

const AccountEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="show"
            submitOnEnter={true}
        />
        {permissions === 'admin' && (
            <SaveButton
                label='resources.actions.save_and_next'
                redirect={false}
                submitOnEnter={false}
                variant="flat"
            />
        )}
    </Toolbar>
);


const AccountCreate = function({ permissions, ...props }) {

    return (
    <Create {...props}>
        <TabbedForm toolbar={<AccountEditToolbar permissions={permissions} />}>
            <FormTab label="resources.accounts.form.summary" path="">
                
                <TextInput
                    source="code"
                    defaultValue=""
                    validate={required()}
                />
                
                <TextInput
                    source="name"
                    defaultValue=""
                />

                <TextInput
                    source="company"
                    defaultValue=""
                />

                <TextInput
                    source="address"
                    defaultValue=""
                />

                <TextInput
                    source="city"
                    defaultValue=""
                />

                <TextInput
                    source="state"
                    defaultValue=""
                />

                <TextInput
                    source="country"
                    defaultValue=""
                />

                <TextInput
                    source="zipcode"
                    defaultValue=""
                />

                <TextInput
                    source="signature_name"
                    defaultValue=""
                />

                <SelectInput source="status" choices={[
                    { id: 'active', name: 'Active' },
                    { id: 'blocked', name: 'Blocked' },
                ]} defaultValue="active" />
                
            </FormTab>
        </TabbedForm>
    </Create>
    )
};

export default AccountCreate;
