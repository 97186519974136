/* eslint react/jsx-key: off */
import ProductIcon from '@material-ui/icons/Storage';
// import StockInListEmbedded from './StockInListEmbedded';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import compose from 'recompose/compose';
import HandlingButton from './HandlingButton';
import StockOutButton from './StockOutButton';
import ProductReferenceField from '../products/ProductReferenceField';
import StockOutEmbedded from './StockOutEmbedded';
import DownloadBoxLabelField from './DownloadBoxLabelField';
import DownloadSkulistField from './DownloadSkulistField';
import DownloadInnerPageField from './DownloadInnerPageField';
import StatusField from './StatusField';
import NoteField from './NoteField';
import DownloadStockoutLabelField from './DownloadStockoutLabelField';

import React, { Fragment } from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    Responsive,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    TextInput,
    TextField,
    NumberField,
    ReferenceField,
    DateField,
    translate,
    Pagination,
    CardActions,
    RefreshButton,
    ExportButton,
    downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import MobileGrid from '../layout/MobileGrid';

import MoveToStockingOutButton from './MoveToStockingOutButton';
import MoveToHandlingButton from './MoveToHandlingButton';
import MoveToCanceledButton from './MoveToCanceledButton';
import MoveToStockedOutButton from './MoveToStockedOutButton';

export const UserIcon = ProductIcon;

const exporter = data => {
    let d = new Date();
    let n = d.getFullYear().toString() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate().toString()).slice(-2) + ('0' + d.getHours()).slice(-2) + ('0' + d.getMinutes()).slice(-2);

    const csv = convertToCSV({data})
    const BOM = '\uFEFF'
    downloadCSV(`${BOM} ${csv}`, 'stockoutrequests-'+n)
}

const StockOutRequestBulkActionButtons = ({permissions, ...props}) => (

    <Fragment>
        { (permissions === "Admin" || permissions === "WM") &&
        <MoveToStockingOutButton {...props} />
        }
        { (permissions === "Admin" || permissions === "WM") &&
        <MoveToHandlingButton {...props} />
        }
        { (permissions === "Admin") &&
        <MoveToStockedOutButton {...props} />
        }
        { (permissions === "Admin" || permissions === "WM" || permissions === "PM") &&
        <MoveToCanceledButton {...props} />
        }
    </Fragment>
);

const StockOutRequestActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>
        <RefreshButton />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </CardActions>
);

const StockOutRequestPagination = props => <Pagination rowsPerPageOptions={[25, 50]} {...props} />

const StockOutRequestFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput
          label="ID"
          type="search"
          source="id"
          margin="normal"
          alwaysOn
        />
        <ReferenceInput source="productId" reference="products" label="resources.stockoutrequests.fields.product" alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.name}`
                }
            />
        </ReferenceInput>
        <ReferenceInput source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user" sort={{ field: 'username', order: 'ASC' }}  perPage={100} alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.username}`
                }
            />
        </ReferenceInput>
        <ReferenceInput source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" sort={{ field: 'code', order: 'ASC'}}  perPage={100} alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.code}`
                }
            />
        </ReferenceInput>
        <SelectInput source="speed" alwaysOn choices={[
                    { id: 'same_day', name: 'resources.stockoutrequests.speed.same_day' },
                    { id: 'urgent', name: 'resources.stockoutrequests.speed.urgent' },
                    { id: 'normal', name: 'resources.stockoutrequests.speed.normal' },
                ]}
        />
        <SelectInput source="country" alwaysOn choices={[
                    { id: 'US', name: 'US' },
                    { id: 'CA', name: 'CA' },
                    { id: 'UK', name: 'UK' },
                    { id: 'DE', name: 'DE' },
                    { id: 'FR', name: 'FR' },
                ]}
        />
    </Filter>
);

class TabbedDatagrid extends React.Component {

    state = { stocking_out: [], handling: [], stocked_out: [], cancelled: [] };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
    };

    render() {

        const { classes, filterValues, translate, permissions, ...props } = this.props;

        return (

            <Fragment>
                <Tabs
                    fullWidth
                    centered
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                        <Tab
                            key="stocking_out"
                            label={translate(`resources.stockoutrequests.tab.stocking_out`)}
                            value="stocking_out"
                        />
                        <Tab
                            key="handling"
                            label={translate(`resources.stockoutrequests.tab.handling`)}
                            value="handling"
                        />
                        <Tab
                            key="stocked_out"
                            label={translate(`resources.stockoutrequests.tab.stocked_out`)}
                            value="stocked_out"
                        />
                        <Tab
                            key="cancelled"
                            label={translate(`resources.stockoutrequests.tab.cancelled`)}
                            value="cancelled"
                        />
                </Tabs>
                <Divider />
                <Responsive
                    xsmall={
                        <MobileGrid
                            {...props}
                            ids={this.state[filterValues.status]}
                        />
                    }
                    medium={
                        <div>
                            {filterValues.status === 'stocking_out' && (
                                <Datagrid {...props} 
                                    ids={this.state.stocking_out} 
                                    expand={<StockOutEmbedded permissions={permissions} />}
                                >
                                    {permissions === 'WM' ? <HandlingButton /> : "" }
                                    {permissions === 'WM' ? <StockOutButton /> : "" }
                                    <TextField source="id" />
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user" linkType={false}>
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                                    {/*<StatusField />*/}
                                    <NumberField source="qty" />
                                    <TextField source="speed" />
                                    <DateField source="stockOutDate" options={{timeZone: "UTC"}} />
                                    <DateField source="stockOutDeadline" options={{timeZone: "UTC"}} />
                                     <TextField source="shipfrom" />
                                    <TextField source="shipto" />
                                   {/*<TextField source="shippingCompany" />*/}
                                    <TextField source="barcode" />
                                    {/*<ReferenceField source="productId" reference="products" allowEmpty emptyValue="" label="resources.products.fields.shelf" style={{width: "50px", display: "block"}}>
                                        <TextField source="shelf" />
                            </ReferenceField>*/}
                                    <DownloadSkulistField />
                                    <DownloadBoxLabelField />
                            
                                    <DownloadInnerPageField />
                                    
                                   
                                    <ReferenceField source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" allowEmpty={true} linkType={false}>
                                        <TextField source="code" />
                                    </ReferenceField>
                                    <TextField source="country" />
                                    <NoteField />
                                    <EditButton />
                                    
                                </Datagrid>
                                
                            )}
                            {filterValues.status === 'handling' && (
                                <Datagrid {...props} 
                                    ids={this.state.handling} 
                                    expand={<StockOutEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    {permissions === 'WM' ? <StockOutButton /> : "" }
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user" linkType={false}>
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                                    <StatusField />
                                    <NumberField source="qty" />
                                    <NumberField source="qtyPending" />
                                    <TextField source="speed" />
                                    <DateField source="stockOutDate" options={{timeZone: "UTC"}} />
                                    <DateField source="stockOutDeadline" options={{timeZone: "UTC"}} />
                                    <TextField source="shipfrom" />
                                    <TextField source="shipto" />
                                    {/*<TextField source="shippingCompany" />*/}
                                    <TextField source="barcode" />
                                    <DownloadSkulistField />
                                    <DownloadBoxLabelField />
                                    
                                    <DownloadInnerPageField />
                    
                                    
                                    
                                    <ReferenceField source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" allowEmpty={true} linkType={false}>
                                        <TextField source="code" />
                                    </ReferenceField>
                                    <TextField source="country" />
                                    <NoteField />
                                    <EditButton />
                                    
                                </Datagrid>
                            )}
                            {filterValues.status === 'stocked_out' && (
                                <Datagrid {...props} 
                                    ids={this.state.stocked_out} 
                                    expand={<StockOutEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user" linkType={false}>
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                                    <StatusField />
                                    <NumberField source="qty" />
                                    <NumberField source="qtyPending" />
                                    <TextField source="speed" />
                                    <DateField source="stockOutDate" options={{timeZone: "UTC"}} />
                                    <DateField source="stockOutDeadline" options={{timeZone: "UTC"}} />
                                    
                                     <TextField source="shipfrom" />
                                    <TextField source="shipto" />
                                    {/*<TextField source="shippingCompany" />*/}
                                    <TextField source="barcode" />
                                    {/*<DownloadSkulistField />*/}
                                    <DownloadBoxLabelField />
                                    <DownloadInnerPageField />
                                    
                                    
                                    <ReferenceField source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" allowEmpty={true} linkType={false}>
                                        <TextField source="code" />
                                    </ReferenceField>
                                    <TextField source="country" />
                                    <TextField source="shipCost" />
                                    <NoteField />
                                    <EditButton />
                                </Datagrid>
                            )}
                            {filterValues.status === 'cancelled' && (
                                <Datagrid {...props} 
                                    ids={this.state.cancelled} 
                                    expand={<StockOutEmbedded permissions={permissions} />}
                                >
                                    <TextField source="id" />
                                    <ReferenceField source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user" linkType={false}>
                                        <TextField source="username" />
                                    </ReferenceField>
                                    <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                                    <StatusField />
                                    <NumberField source="qty" />
                                    <DateField source="stockOutDate" options={{timeZone: "UTC"}} />
                                    
                                    <TextField source="shippingCompany" />
                                    <TextField source="barcode" />
                                    <ReferenceField source="productId" reference="products" allowEmpty emptyValue="" label="resources.products.fields.shelf" style={{width: "50px", display: "block"}}>
                                        <TextField source="shelf" />
                                    </ReferenceField>
                                    <DownloadBoxLabelField />
                                    <DownloadInnerPageField />
                                    <TextField source="speed" />
                                    <DateField source="stockOutDeadline" options={{timeZone: "UTC"}} />
                                    <ReferenceField source="accountId" reference="accounts" label="resources.stockoutrequests.fields.account" allowEmpty={true} linkType={false}>
                                        <TextField source="code" />
                                    </ReferenceField>
                                    <TextField source="country" />
                                    <NoteField />
                                    <EditButton />
                                </Datagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );

    }
}

const StockOutRequestList = ({ permissions, ...props }) => (
    <List
        {...props}
        filterDefaultValues={{ status: 'stocking_out' }}
        sort={{ field: 'modified', order: 'DESC' }}
        perPage={50}
        pagination={<StockOutRequestPagination />}
        actions={<StockOutRequestActions />}
        bulkActionButtons={<StockOutRequestBulkActionButtons permissions={permissions} />}
        filters={<StockOutRequestFilter />}
        exporter={exporter}
    >
        <TabbedDatagrid permissions={permissions} />
    </List>
);

const enhance = compose(
    translate
);

export default enhance(StockOutRequestList);
