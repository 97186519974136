import chineseMessages from 'ra-language-chinese';

export default {
    ...chineseMessages,
    pos: {
        search: '搜索',
        edit: '编辑',
        view: '查看',
        configuration: '配置',
        language: '语言',
        theme: {
            name: '主题',
            light: '明亮',
            dark: '灰暗',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            new_customers: 'New Customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to react-admin demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Fell free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                aor_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            products: '产品列表',
            all_products: '所有产品',
            my_products: '我的产品',
            catalog: 'Catalog',
            customers: 'Customers',
            packages: '包材',
            purchases: '订单',
            payments: '付款申请',
            purchases_product: '产品',
            purchases_shipping: '运输',
            purchases_service: '服务商',
            purchases_others: '其他',
        },
    },
    resources: {
        actions: { 
            save_and_show: "保存",
        },
        vendor: {
            menu: '合作公司列表',
            name: '公司 |||| 公司',
            form: {
                summary: '合作公司',
                image: '二维码',
                box: '箱',
            },
            fields: {
                image: "二维码",
                current_image: "当前二维码",
                name: "公司名称",
                deliveryDates: "生产周期",
                category: "公司类型",
                phone: "电话",
                address: "地址",
                shippingPrice: "邮费",
                paymentMethod: "付款方式",
                factory1688Link: "1688链接",
                notes: "备注",
                currency: "货币类型",
            },
        },
        products: {
            menu: '产品列表',
            name: '产品 |||| 产品',
            form: {
                summary: '产品信息',
                image: '产品图片',
                box: '箱',
            },
            fields: {
                image: "产品图片",
                current_image: "当前图片",
                name: "产品名称",
                boxmark_name: "箱唛名称(英文)",
                brand: "品牌",
                shelf: "货架",
                qty: "库存数量",
                qtyInBox: "每箱产品数量",
                box_weight: "一览重量(磅)",
                box_size: "箱子尺寸",
                unitPrice: "单价",
                upcoming_qty: "未入库数量",
                asin: "ASIN",
                country: "卖场",
                user: "产品经理",
                package: "对应包材",
                supplier_name: "供应商名称",
                supplier_link: "供应商链接",
                status: "状态",
                last_stockout: "上次发货"
            },
            edit: {
                title: '产品 "%{title}"',
            },
            actions: {
                purchase: '产品订单',
                shiporder: '运输订单',
                serviceorder: '服务商订单',
                stock_in: '入库',
                stock_in_request: '入库申请',
                stock_out_request: '发货申请',
                create_package: '创建包材'
            },
            record: {
                stock_in_request: '入库申请记录',
                stock_out_request: '发货申请记录'
            },
            tab: {
                active: 'Active',
                inactive: 'Inactive'
            },
            moveto: {
                active: '转移至 Active',
                inactive: '转移至 Inactive'
            }
        },
        packages: {
            menu: '包材列表',
            name: '包材 |||| 包材',
            form: {
                summary: '包材信息',
                box: '箱',
            },
            fields: {
                product: "对应产品",
                name: "包材名称",
                qty: "库存数量",
                qtyInBox: "每箱包材数量",
                box_weight: "一览重量(磅)",
                box_size: "箱子尺寸",
                upcoming_qty: "未入库数量",
                user: "产品经理",
            },
            edit: {
                title: '包材 "%{title}"',
            },
            actions: {
                purchase: '采购'
            },
            record: {
                purchase: '采购记录'
            }
        },
        purchases: {
            menu: '产品采购',
            name: '订单 |||| 订单',
            form: {
                summary: '订单信息'
            },
            fields: {
                id: "订单ID",
                name: "名称",
                status: "状态",
                qty: "数量",
                unit_price: "单价",
                unit_price_cny: "单价CNY",
                total_price: "总价",
                total_price_cny: "总价CNY）",
                note: "备注",
                user: "产品经理",
                product: "对应产品",
                package: "对应包材",
                vendor: "对应公司",
                account: "采购账号",
                paid: "付款申请",
                contract: "合同",
                barcode: "Barcode",
                fnsku: "FNSKU",
                neiye: "透明标",
                transport: "运输方式",
                other_transport: "自定义运输方式",
                created:"时间"
            },
            edit: {
                title: '订单 "%{title}"',
            },
            actions: {
                payment: '付款申请',
                productstockin: '入库申请',
                packagestockin: '包材到货',
                makeboxmark: '打印箱唛',
                viewboxmark: '查看箱唛',
                uploadcontract: '上传合同',
            },
            record: {
                payment: '付款申请记录',
                stock_in_request: '入库申请记录'
            },
            tab: {
                pending: '新订单',
                printed_boxmark: '已打印箱唛',
                stocked_in: '已提交入库申请',
                canceled: '已中止',
            },
            moveto: {
                printed_boxmark: '转移至已打印箱唛',
                stocked_in: '转移至已提交入库申请',
                canceled: '转移至已中止',
            },
            transport: {
                sea: '海运',
                air: '空运',
                other: '自定义',
            }
        },
        payments: {
            menu: '付款申请',
            name: '付款申请 |||| 付款申请',
            form: {
                summary: '付款申请'
            },
            fields: {
                id: "付款ID",
                status: "状态",
                percentage: "付款比例",
                amount: "付款额度",
                amount_cny: "付款额度（人民币）",
                payment_date: "付款时间",
                payment_type: "付款类型",
                product_received_date: "签收",
                note: "备注",
                user: "产品经理",
                purchase: "对应订单",
                purchaseId: "订单ID",
                purchase_type: "订单类型",
                vendor: "公司",
            },
            edit: {
                title: '付款 "%{title}"',
            },
            tab: {
                pending: '未付款',
                preparation: '账期款项',
                preapproved: '预备批准',
                approved: '已批准',
                paid: '已付款',
                canceled: '已终止',
                special: '特殊时期款项',
            },
            moveto: {
                pending: '转移至未付款',
                preparation: '转移至账期款项',
                preapproved: '转移至预备批准',
                approved: '转移至批准',
                paid: '转移至已付款',
                canceled: '转移至已终止',
                special: '转移至特殊时期款项',
            }
        },
        appusers: {
            menu: '用户管理',
            name: '用户 |||| 用户',
            fields: {
            },
            edit: {
                title: '用户 "%{title}"',
            }
        },
        accounts: {
            menu: '账户管理',
            name: '账户 |||| 账户',
            form: {
                summary: '账户信息'
            },
            fields: {
                name: "账户名称",
                code: "账户代码",
                company: "公司名称",
            },
            tab: {
                active: "Active",
                blocked: "Blocked"
            }
        },
        stockinrequests: {
            menu: '入库申请',
            name: '入库申请 |||| 入库申请',
            form: {
                summary: '入库申请信息'
            },
            fields: {
                product: "入库产品",
                boxes: "总箱数",
                qtyInBox: "每箱产品数量",
                weightPerBox: "每箱重量(lb)",
                qtyInTrailBox: "尾箱产品数量",
                qty: "采购总数量",
                qtyBatch: "此笔入库总数量",
                qtyStockedIn: "已入库数量",
                qtyPending: "未入库数量",
                warehouse: "仓库",
                waybill: "运单号",
                estArrivalDate: "预计入库时间",
                shippingCompany: "邮递公司",
                status: "状态",
                user: "入库申请人",
                purchase: "对应采购",
                purchaseId: "采购ID",
                boxes_start: "起始箱号",
                boxes_end: "结束箱号",
                shippingFee: "运费"
            },
            actions: {
                stock_in: '单箱入库',
                stock_in_left: '全部入库',
            },
            tab: {
                stocking_in: '未入库',
                processing: '入库中',
                stocked_in: '已入库',
                cancelled: '已终止'
            },
            record: {
                stockins: '已入库清单',
                stockins_list: '已入库清单',
                stockins_left: '未入库清单',
                purchase_boxes: '采购总箱数',
                batch_boxes: '此笔入库总箱数',
                purchase_qty: '采购总数量',
                batch_qty: '此笔入库总数量',
            }
        },
        packagestockins: {
            menu: '包材到货',
            name: '包材到货 |||| 包材到货',
            form: {
                summary: '到货信息'
            },
            fields: {
                qty: '到货数量',
                warehouse: "目的地",
                stockInDate: '到货时间',
                package: '包材',
                purchase: '对应采购',
                user: '创建人'
            },
            actions: {
                stockin: '到货'
            },
            record: '包材到货记录'
        },
        packagestockouts: {
            menu: '包材出货',
            name: '包材出货 |||| 包材出货',
            form: {
                summary: '出货信息'
            },
            fields: {
                qty: '出货数量',
                warehouse: "目的地",
                stockOutDate: '出货时间',
                package: '包材',
                purchase: '对应采购',
                user: '创建人'
            },
            actions: {
                stockout: '出货'
            },
            record: '包材出货记录'
        },
        stockins: {
            menu: ' — 已入库清单',
            name: '入库 |||| 入库',
            form: {
                summary: '入库信息'
            },
            fields: {
                qty: '已入库数量',
                box: '箱号',
                warehouse: "仓库",
                stockInDate: '入库时间',
                expiryDate: '有效期',
                product: '入库产品',
                stock_in_request: '入库申请ID',
                user: '仓管员'
            },
            desc: {
                qty: '产品数量'
            }
        },
        stockoutrequests: {
            menu: '发货申请',
            name: '发货申请 |||| 发货申请',
            form: {
                summary: '发货申请信息'
            },
            fields: {
                product: "发货产品",
                qty: "请求发货数量",
                qtyPending: "未发货数量",
                warehouse: "仓库",
                stockOutDate: "创建时间",
                shippingCompany: "邮递公司",
                innerPage: "透明标",
                current_innerPage: "当前透明标",
                speed: "发货速度",
                stockOutDeadline: "发货Deadline",
                status: "状态",
                boxLabel: "Amazon 上传箱标",
                current_boxLabel: "当前Amazon 上传箱标",
                shipfrom: "发件人",
                shipto: "收件地址",
                user: "发货申请人",
                stockOutAccount: "发货账户",
                note: "备注",
                images: "产品图片",
                current_images: "当前产品图片",
                country: "卖场",
                account: "发货账户"
            },
            actions: {
                stock_out: '发货',
                handling: '处理',
                download_boxbabel: "下载箱标",
            },
            tab: {
                stocking_out: '未发货',
                handling: '处理中',
                stocked_out: '已发货',
                cancelled: '已终止'
            },
            moveto: {
                stocking_out: '转移至未发货',
                handling: '转移至处理中',
                stocked_out: '转移至已发货',
                cancelled: '转移至已终止'                
            },
            speed: {
                same_day: '当日发出',
                urgent: '紧急',
                normal: '普通'
            }
        },
        stockouts: {
            menu: ' — 发货清单',
            name: '发货 |||| 发货',
            form: {
                summary: '发货信息'
            },
            fields: {
                qty: '发货数量',
                warehouse: "仓库",
                stockOutDate: '发货时间',
                product: '发货产品',
                stock_out_request: '发货申请',
                user: '仓管员'
            }
        },
        finances: {
            menu: '财务管理',
            name: '财务 |||| 财务',
            form: {
                summary: '财务信息'
            },
            fields: {
                date: "日期",
                type: "类型",
                amount: "金额",
                description: "说明",
                balance: "余额",
                note: "备注",
                user: "财务经理"
            },
            type: {
                income: "进账（收入）",
                outcome: "出账（支出）"
            }
        },
    },
};