import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import {
    ImageField,
    EditButton,
    translate,
    NumberField,
    TextField,
} from 'react-admin';

import RequestButton from './RequestButton';
import PackageButton from './PackageButton';

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: theme.typography.body1,
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
});

const MobileGrid = ({ classes, ids, data, basePath, translate, permissions }) => (
    <div style={{ margin: '1em' }}>
        {ids.map(id => (
            <Card key={id} className={classes.card}>
                <CardHeader
                    title={
                        <div className={classes.cardTitleContent}>
                            <span>
                                <TextField
                                    record={data[id]}
                                    source="name"
                                    style={{fontWeight: "bold", fontSize: "18px"}}
                                />
                            </span>

                            { permissions === "Admin" || permissions === "PM" || permissions === "PB" || permissions === "WM" ? <EditButton basePath={basePath} record={data[id]} /> : "" }
                        </div>
                    }
                />
                <CardContent className={classes.cardContent}>
                    <span><ImageField source="image" record={data[id]} /></span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.products.fields.boxmark_name')}:&nbsp;
                        <TextField record={data[id]} source="boxmark_name" />
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.products.fields.qty')}:&nbsp;
                        <NumberField source="qty" record={data[id]} />
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.products.fields.shelf')}
                        :&nbsp;
                        <NumberField record={data[id]} source="shelf" />
                    </span>
                    <span>&nbsp;</span>
                    
                    <Grid container spacing={1}>
                      <Grid container item xs={6} spacing={1}>
                        { permissions === "PM" ? <PackageButton source="package" record={data[id]} /> : "" }
                      </Grid>
                      <Grid container item xs={6} spacing={1}>
                         { permissions === "PM" ? <RequestButton record={data[id]} /> : "" }
                      </Grid>
                    </Grid>

                </CardContent>
            </Card>
        ))}
    </div>
);

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

const enhance = compose(
    withStyles(listStyles),
    translate
);

export default enhance(MobileGrid);