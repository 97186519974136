/* eslint react/jsx-key: off */
import PeopleIcon from '@material-ui/icons/People';
import memoize from 'lodash/memoize';
import Aside from './Aside';
import RoleField from './RoleField';

import React from 'react';
import {
    Datagrid,
    Filter,
    List,
    Responsive,
    SearchInput,
    SimpleList,
    Pagination,
    TextField,
    TextInput,
    EditButton,
    downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = data => {
    let d = new Date();
    let n = d.getFullYear().toString() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate().toString()).slice(-2) + ('0' + d.getHours()).slice(-2) + ('0' + d.getMinutes()).slice(-2);

    const csv = convertToCSV({data})
    const BOM = '\uFEFF'
    downloadCSV(`${BOM} ${csv}`, 'users-'+n)
}

export const UserIcon = PeopleIcon;

const UserFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput source="username" alwaysOn />
    </Filter>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'WM'
        ? Promise.resolve('edit')
        : Promise.resolve('show');
});

const UserPagination = props => <Pagination rowsPerPageOptions={[50, 100, 200, 300]} {...props} />
const UserList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<UserFilter permissions={permissions} />}
        sort={{ field: 'id', order: 'ASC' }}
        perPage={50}
        pagination={<UserPagination />}
        aside={<Aside />}
        exporter={exporter}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.username}
                    secondaryText={record =>
                        permissions === 'admin' ? record.role : null
                    }
                />
            }
            medium={
                <Datagrid
                    rowClick={rowClick(permissions)}
                >
                    <TextField source="username" />
                    <TextField source="email" />
                    <RoleField label="Role"/>
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
);

export default UserList;
