import React from 'react';

const CurrentBarcodeField = function({ record = {} }) { 
	
	const barcodes = typeof record.barcode === "string" ? record.barcode.split(",") : '';
	const items = [];

	for (const value of barcodes) {
	    items.push(<li><a href={value} target="_blank" rel="noopener noreferrer">{unescape(decodeURI(value).substr(decodeURI(value).lastIndexOf('/') + 1))}</a></li>)
	}

    return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
    	<ul style={{listStyle: "none", padding: 0}}>
    		{items}
    	</ul>
    </div>
    )
};

CurrentBarcodeField.defaultProps = {
    source: 'barcode',
    addLabel: false,
};

export default CurrentBarcodeField;