import React, { Component }  from 'react';
import compose from 'recompose/compose';
import {translate} from 'react-admin';

class DownloadContractField extends Component {

    render() {
        const { record } = this.props;
        const contracts = typeof record.contract === "string" ? record.contract.split(",") : '';
        const items = [];

        if(contracts !== "") {
            for (const value of contracts) {
                items.push(<li><a href={value} target="_blank" rel="noopener noreferrer">{unescape(decodeURI(value).substr(decodeURI(value).lastIndexOf('/') + 1))}</a></li>)
            }           
        }


	    return (
            <ul style={{listStyle: "none", padding: 0}}>
            { items }
            </ul>
	    )
	}
};

DownloadContractField.defaultProps = {
    source: 'contract',
    addLabel: true,
};

const enhance = compose(
    translate
);

export default enhance(DownloadContractField);