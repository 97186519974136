import axios from 'axios';
import {loobackApiUrl} from '../config';
import moment from 'moment';

var change_field = "";

const autoUpdatePrice = function(event) {

  if(event.target){
    change_field = event.target.id;
  }

  if(change_field === "qty") {
    if(event.unit_price && event.unit_price_cny) {
      if(event.qty !== undefined) {
        event.total_price = (event.qty * event.unit_price).toFixed(2);
        event.total_price_cny = (event.qty * event.unit_price_cny).toFixed(2);   
      }
    }
  }

  if(change_field === "unit_price") {
        axios.get(loobackApiUrl + '/Currencies/findOne', { params: {"filter": {"where": {"date": moment().format("YYYY-MM-DD"), "base": "USD", "target": "CNY"}}}}).then((res) => {

            if(res.status === 200) {
                event.unit_price_cny = (parseFloat(event.unit_price) * parseFloat(res.data.exchange_rate)).toFixed(2);
            }
            
          }).catch( (error) => {

            axios.get('https://api.apilayer.com/exchangerates_data/'+moment().format("YYYY-MM-DD"), 
            {
                params: {
                  'apikey': 'qWRs2bevgHqCUZ9BbcLyeXCf7oZzdTj2',
                  'base': "USD",
                  'symbols': "CNY"
                }
            }).then((res) => {

                let exchange_rate = res.data.rates["CNY"];

                if(res.status === 200) {

                      axios.post(loobackApiUrl + '/Currencies', {
                        "date": moment().format("YYYY-MM-DD"),
                        "base": "USD",
                        "target": "CNY",
                        "exchange_rate": exchange_rate
                      }).then((res) => {
                          event.unit_price_cny = (parseFloat(event.unit_price) * parseFloat(exchange_rate)).toFixed(2);
                          if(event.qty !== undefined) {
                            event.total_price_cny = (parseFloat(event.qty) * parseFloat(event.unit_price_cny)).toFixed(2); 
                          }
                      })
         
                }

            })            
          }).then(() => {
            if(event.qty !== undefined) {
              event.total_price = (parseFloat(event.qty) * parseFloat(event.unit_price)).toFixed(2);
              event.total_price_cny = (parseFloat(event.qty) * parseFloat(event.unit_price_cny)).toFixed(2); 
            }
          });    
  }

  if(change_field === "unit_price_cny") {
        axios.get(loobackApiUrl + '/Currencies/findOne', { params: {"filter": {"where": {"date": moment().format("YYYY-MM-DD"), "base": "CNY", "target": "USD"}}}}).then((res) => {
            if(res.status === 200) {
                event.unit_price = (parseFloat(event.unit_price_cny) * parseFloat(res.data.exchange_rate)).toFixed(2);
            }
            
          }).catch( (error) => {

            axios.get('https://api.apilayer.com/exchangerates_data/'+moment().format("YYYY-MM-DD"), 
            {
                params: {
                  'apikey': 'qWRs2bevgHqCUZ9BbcLyeXCf7oZzdTj2',
                  'base': "CNY",
                  'symbols': "USD"
                }
            }).then((res) => {

                let exchange_rate = res.data.rates["USD"];

                if(res.status === 200) {

                    axios.post(loobackApiUrl + '/Currencies', {
                      "date": moment().format("YYYY-MM-DD"),
                      "base": "CNY",
                      "target": "USD",
                      "exchange_rate": exchange_rate
                    }).then((res) => {
                        event.unit_price = (parseFloat(event.unit_price_cny) * parseFloat(exchange_rate)).toFixed(2);
                        if(event.qty !== undefined) {
                          event.total_price = (parseFloat(event.qty) * parseFloat(event.unit_price)).toFixed(2);
                        }
                    })
                }

            })            
          }).then(() => {
            if(event.qty !== undefined) {
              event.total_price_cny = (parseFloat(event.qty) * parseFloat(event.unit_price_cny)).toFixed(2);
              event.total_price = (parseFloat(event.qty) * parseFloat(event.unit_price)).toFixed(2);
            }
          });

  }

  if(change_field === "total_price") {
    axios.get(loobackApiUrl + '/Currencies/findOne', { params: {"filter": {"where": {"date": moment().format("YYYY-MM-DD"), "base": "USD", "target": "CNY"}}}}).then((res) => {

        if(res.status === 200) {
            event.total_price_cny = (parseFloat(event.total_price) * parseFloat(res.data.exchange_rate)).toFixed(2);
        }
        
      }).catch( (error) => {

        axios.get('https://api.apilayer.com/exchangerates_data/'+moment().format("YYYY-MM-DD"), 
        {
            params: {
              'apikey': 'qWRs2bevgHqCUZ9BbcLyeXCf7oZzdTj2',
              'base': "USD",
              'symbols': "CNY"
            }
        }).then((res) => {

            let exchange_rate = res.data.rates["CNY"];

            if(res.status === 200) {

                  axios.post(loobackApiUrl + '/Currencies', {
                    "date": moment().format("YYYY-MM-DD"),
                    "base": "USD",
                    "target": "CNY",
                    "exchange_rate": exchange_rate
                  }).then((res) => {
                      event.total_price_cny = (parseFloat(event.total_price) * parseFloat(exchange_rate)).toFixed(2);
          
                  })
     
            }

        })            
      });    
  }
  if(change_field === "total_price_cny") {
    axios.get(loobackApiUrl + '/Currencies/findOne', { params: {"filter": {"where": {"date": moment().format("YYYY-MM-DD"), "base": "CNY", "target": "USD"}}}}).then((res) => {
        if(res.status === 200) {
            event.total_price = (parseFloat(event.total_price_cny) * parseFloat(res.data.exchange_rate)).toFixed(2);
        }
        
      }).catch( (error) => {

        axios.get('https://api.apilayer.com/exchangerates_data/'+moment().format("YYYY-MM-DD"), 
        {
            params: {
              'apikey': 'qWRs2bevgHqCUZ9BbcLyeXCf7oZzdTj2',
              'base': "CNY",
              'symbols': "USD"
            }
        }).then((res) => {

            let exchange_rate = res.data.rates["USD"];

            if(res.status === 200) {

                axios.post(loobackApiUrl + '/Currencies', {
                  "date": moment().format("YYYY-MM-DD"),
                  "base": "CNY",
                  "target": "USD",
                  "exchange_rate": exchange_rate
                }).then((res) => {
                    event.total_price = (parseFloat(event.total_price_cny) * parseFloat(exchange_rate)).toFixed(2);
                })
            }

        })            
      });

  }
}

export default autoUpdatePrice;