import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class CreatePurchasesButton extends Component {

    handlePurchaseCreateClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        const urlParams = new URLSearchParams(record.location.search);
        const category = urlParams.get('category') ? urlParams.get('category') : ''
        alert(category);
        window.location = '#/purchases/create?category=='+{category};  
    }

    
    render() {
        return (
            <Button  label="create" onclick={this.handlePurchaseCreateClick}  color="primary"  variant="raised">Create</Button> 
        );
    }
}

CreatePurchasesButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(CreatePurchasesButton);