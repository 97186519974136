/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    FormTab,
    DisabledInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    ImageInput,
    ImageField,
    TabbedForm,
    TextInput,
    NumberInput,
    DateInput,
    Toolbar,
    SaveButton,
    required,
    ReferenceArrayInput,
    AutocompleteArrayInput
} from 'react-admin';
import Aside from './Aside';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import ProductTitle from './ProductTitle';
import { dateFormatter, dateParser } from '../lib/date';

const ProductEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const styles = {
    name: { display: 'inline-block'},
    boxmark_name: { display: 'inline-block', marginLeft: 32 },
    supplier_name: { display: 'inline-block'},
    supplier_link: { display: 'inline-block', marginLeft: 32 },
};

const userId = JSON.parse(localStorage.getItem('lbtoken')) ? JSON.parse(localStorage.getItem('lbtoken')).value.userId : "";

const ProductEdit = ({ classes, permissions, ...props }) => (
    <Edit title={<ProductTitle />} aside={<Aside />} {...props}>
        <TabbedForm toolbar={<ProductEditToolbar permissions={permissions} />}>
            <FormTab label="resources.products.form.summary" path="">
                
                <TextInput
                    source="name"
                    defaultValue=""
                    validate={required()}
                    formClassName={classes.name}
                />
                <TextInput
                    source="boxmark_name"
                    defaultValue=""
                    formClassName={classes.boxmark_name}
                />
                <TextInput
                    source="brand"
                    defaultValue=""
                />
                <ReferenceInput source="vendorId" reference="vendor" label="对应工厂" perPage={1000} sort={{ field: 'name', order: 'ASC' }}>
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <ReferenceArrayInput label="工厂列表" reference="vendor" source="vendorlist" perPage={1000} sort={{ field: 'name', order: 'ASC' }}>
                    <AutocompleteArrayInput />
                </ReferenceArrayInput>
                <TextInput
                    source="asin"
                    defaultValue=""
                />
                <SelectInput source="country" allowEmpty emptyValue="" choices={[
                    { id: 'US', name: 'US' },
                    { id: 'UK', name: 'UK' },
                    { id: 'CA', name: 'CA' },
                    { id: 'DE', name: 'DE' },
                ]} />
                
                

                <SelectInput source="status" choices={[
                    { id: 'active', name: 'Active' },
                    { id: 'inactive', name: 'Inactive' },
                ]} />
                <ImageInput source="image" accept="image/*" label="resources.products.fields.image">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <ImageField source="image" title="title" label="resources.products.fields.current_image"/>
                <ReferenceInput source="appUserId" allowEmpty emptyValue=""  reference="appusers" label="resources.products.fields.user" perPage={100} filterToQuery={searchText => ({realm:'PM',username: {"like": `%${searchText}%`, "options": "i"}})} sort={{ field: 'id', order: 'ASC' }}>
                    <AutocompleteInput optionText="username" />
                </ReferenceInput>


                <div>
                    <TextInput
                        source="shelf"
                        defaultValue=""
                        label="resources.products.fields.shelf"
                    />
                    <NumberInput
                        source="qty"
                        defaultValue=""
                        validate={required()}
                        formClassName={classes.qty_stl}
                        label="resources.products.fields.qty"
                    />
                    <TextInput id="unitPrice" label="resources.products.fields.unitPrice" source="unitPrice" 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
                    <NumberInput
                        source="qtyInBox"
                        label="resources.products.fields.qtyInBox"
                    />
                    <NumberInput
                        source="box_weight"
                        label="resources.products.fields.box_weight"
                    />
                    <TextInput
                        source="box_size"
                        defaultValue=""
                        label="resources.products.fields.box_size"
                    />
                    <DateInput
                        source="expiryDate"
                        label="resources.stockins.fields.expiryDate"
                        format={dateFormatter} parse={dateParser}
                    />
                </div>
   
                
            </FormTab>
        </TabbedForm>
    </Edit>
);

ProductEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default withStyles(styles)(ProductEdit);
