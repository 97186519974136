import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';
import {apiUrl} from '../config';

class MakeBoxMarkButton extends Component {
    
    handleClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        let boxes = record.boxes===null?'':record.boxes;
        let qtyInBox = record.qtyInBox===null?'':record.qtyInBox;
        let qtyInTrailBox = record.qtyInTrailBox===null?'':record.qtyInTrailBox;
        let weightPerBox = record.weightPerBox===null?'':record.weightPerBox;
        window.open(apiUrl + '/makeboxmark/index.php?auth='+JSON.parse(localStorage.getItem('lbtoken')).value.id+'&purchase_id='+record.id+'&product_id='+record.productId+'&qty='+record.qty+'&boxes='+boxes+'&qtyInBox='+qtyInBox+'&qtyInTrailBox='+qtyInTrailBox+'&weightPerBox='+weightPerBox);  
    }

    render() {
        return (
        <div>
            <Button label="makeboxmark" onClick={this.handleClick} color="primary" variant="raised">{this.props.record.status === "printed_boxmark" ? this.props.translate('resources.purchases.actions.viewboxmark') : this.props.translate('resources.purchases.actions.makeboxmark')}</Button>
        </div>
        );
    }
}

MakeBoxMarkButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(MakeBoxMarkButton);