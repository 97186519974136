import React from 'react';
import {
    Edit,
    NumberInput,
    TextInput,
    SelectInput,
    SimpleForm,
    ImageInput,
    ImageField,
    LongTextInput
} from 'react-admin';

import {companyCategoryOptions,currencyOptions} from '../common/options';

export const VendorEdit = props => (
    <Edit {...props} title="resources.vendor.form.summary">
        <SimpleForm>
            <NumberInput source="id" disabled/>
            <TextInput source ="name" />
            <TextInput source ="deliveryDates" />
            <SelectInput source="category"  choices={companyCategoryOptions}/>
            <TextInput source="phone" />
            <TextInput source="address" />
            <NumberInput source="shippingPrice" />
            <LongTextInput source="paymentMethod" multiline  />
            <LongTextInput source="factory1688Link" />
            
            <ImageInput source="QRCode" accept="image/*" >
                    <ImageField source="src" title="title" />
                </ImageInput>
            <ImageField source="QRCode" title="title" label="resources.vendor.fields.current_image"/>
            <TextInput source="notes" multiline  />
            <SelectInput source="currency" choices={currencyOptions} />
        </SimpleForm>
    </Edit>
);