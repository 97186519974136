import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class RequestButton extends Component {

    handleStockOutClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        window.location = '#/stockoutrequests/create?product_id='+record.id;  
    }

    handlePurchaseClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        let vendorId = record.vendorId===null?'':record.vendorId;
        window.location = '#/purchases/create?category=factory&product_id='+record.id+'&vendor_id='+record.vendorId;  
    }
    handleShipOrderClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        let vendorId = record.vendorId===null?'':record.vendorId;
        window.location = '#/purchases/create?category=shipping&product_id='+record.id+'&name='+record.name+'-运输';  
    }
    handleServiceOrderClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        let vendorId = record.vendorId===null?'':record.vendorId;
        window.location = '#/purchases/create?category=service&product_id='+record.id+'&name='+record.name+'-服务商';  
    }

    render() {
        return (
        <div>
            <Button label="purchase" onClick={this.handlePurchaseClick} variant="raised">{this.props.translate('resources.products.actions.purchase')}</Button>&nbsp;
            <Button label="Ship" onClick={this.handleShipOrderClick} variant="raised">{this.props.translate('resources.products.actions.shiporder')}</Button>&nbsp;
            <Button label="Service" onClick={this.handleServiceOrderClick} variant="raised">{this.props.translate('resources.products.actions.serviceorder')}</Button>&nbsp;
            <Button label="stock_out_request" onClick={this.handleStockOutClick} color="secondary" variant="raised">{this.props.translate('resources.products.actions.stock_out_request')}</Button>
        </div>
        );
    }
}

RequestButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(RequestButton);