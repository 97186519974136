/* eslint react/jsx-key: off */
import memoize from 'lodash/memoize';

import Button from '@material-ui/core/Button';
import React from 'react';
import {
    Filter,
    List,
    Responsive,
    SearchInput,
    SelectInput,
    DateInput,
    DateField,
    ReferenceField,
    SimpleList,
    TextField,
    Pagination,
    EditButton,
    downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import FinanceDatagrid from '../lib/FinanceDatagrid';

const exporter = finances => {
    const financesForExport = finances.map(finance => {
        finance.date = new Date(finance.date).toISOString().split('T')[0];
        return finance;
    });
    let d = new Date();
    let n = d.getFullYear().toString() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate().toString()).slice(-2) + ('0' + d.getHours()).slice(-2) + ('0' + d.getMinutes()).slice(-2);
    const csv = convertToCSV({data: financesForExport})
    const BOM = '\uFEFF'
    downloadCSV(`${BOM} ${csv}`, 'finances-'+n)
}

const FinanceFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <SelectInput source="type" alwaysOn choices={[
                    { id: 'income', name: 'resources.finances.type.income' },
                    { id: 'outcome', name: 'resources.finances.type.outcome' },
                ]}
        />
        <DateInput source="start" alwaysOn options={{timeZone: "UTC"}} label="Start Date" />
        <DateInput source="end" alwaysOn options={{timeZone: "UTC"}} label="End Date" />
        <Button onClick={() => props.setFilters({
          status: props.filterValues.status,
        })} alwaysOn>Clear filters</Button>
    </Filter>
);

const TypeField = ({ record = {} }) => <span>{record.type === "income" ? "进账（收入）" : "出账（支出）"}</span>;
TypeField.defaultProps = {
    source: 'type',
    addLabel: true,
};

const AmountField = ({ record = {} }) => <span>{record.amount ? "¥" + record.amount : ""}</span>;
AmountField.defaultProps = {
    source: 'amount',
    addLabel: true,
};

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'WM'
        ? Promise.resolve('edit')
        : Promise.resolve('show');
});

const FinancePagination = props => <Pagination rowsPerPageOptions={[100, 200, 300]} {...props} />

const FinanceList = ({ permissions, ...props }) => (
    <List
        {...props}
        perPage={100}
        pagination={<FinancePagination />}
        filters={<FinanceFilter permissions={permissions} sort={{ field: 'date', order: 'DESC' }} />}
        sort={{ field: 'date', order: 'DESC' }}
        exporter={exporter}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.username}
                    secondaryText={record =>
                        record.balance
                    }
                />
            }
            medium={
                <FinanceDatagrid
                    rowClick={rowClick(permissions)}
                >
                    <TextField source="id" />
                    <DateField source="date" options={{timeZone: "UTC"}}/>
                    <TypeField />
                    <AmountField />
                    <TextField source="description"/>
                    <TextField source="note" style={{width: "350px", display: "block"}}/>
                    <ReferenceField source="appUserId" reference="appusers" label="resources.finances.fields.user">
                        <TextField source="username" />
                    </ReferenceField>
                    <EditButton />
                </FinanceDatagrid>
            }
        />
    </List>
);

export default FinanceList;
