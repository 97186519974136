import React from 'react';
import { Show, EditButton, TextField, NumberField, ReferenceManyField, Datagrid, DateField, ReferenceField, Pagination, translate } from 'react-admin';
import compose from 'recompose/compose';
import NoteField from '../stock_out_requests/NoteField';
import StockInStatusField from '../stock_in_requests/StatusField';
import StockInEmbedded from '../stock_in_requests/StockInEmbedded';

const PercentageField = ({ record = {} }) => <span>{record.percentage * 100}%</span>;
PercentageField.defaultProps = {
    source: 'percentage',
    addLabel: true,
};

const PaymentIdField = ({ record }) => (
    <span><a href={`/#/payments/${record.id}`} style={{textDecoration: "none"}}>{record.purchaseId ? 'OR' + record.purchaseId.toString().padStart(4, '0') + 'P' + record.count : ''}</a></span>
);

const PurchaseEmbedded = function({ permissions, ...props }) {
    
    return (
    /* Passing " " as title disables the custom title */
    <div>
        <h4 style={{fontWeight: 'normal'}}>{props.translate('resources.purchases.record.payment')}:</h4>
        <Show {...props}>
                <ReferenceManyField
                        reference="payments"
                        target="purchaseId"
                        pagination={<Pagination />}
                    >
                        <Datagrid>
                            <PaymentIdField label="resources.payments.fields.id" />
                            <ReferenceField source="appUserId" reference="appusers" label="resources.stockinrequests.fields.user" >
                                <TextField source="username" />
                            </ReferenceField>
                            <TextField source="status" />
                            <NumberField source="amount" options={{ style: 'currency', currency: 'USD' }} />
                            <NumberField source="amount_cny" options={{ style: 'currency', currency: 'CNY' }} />
                            <PercentageField />
                            <DateField source="payment_date" options={{timeZone: "UTC"}}/>
                            <TextField source="payment_type" />
                            <NoteField />
                            <EditButton />
                        </Datagrid>
                </ReferenceManyField>
        </Show>
        <h4 style={{fontWeight: 'normal'}}>{props.translate('resources.purchases.record.stock_in_request')}:</h4>
        <Show {...props}>
                <ReferenceManyField
                        reference="stockinrequests"
                        target="purchaseId"
                        pagination={<Pagination />}
                    >
                        <Datagrid expand={<StockInEmbedded permissions={permissions} />}>
                            <TextField source="id" />
                            <ReferenceField source="appUserId" reference="appusers" label="resources.stockinrequests.fields.user" >
                                <TextField source="username" />
                            </ReferenceField>
                            <StockInStatusField />
                            <NumberField source="boxes" />
                            <NumberField source="qtyInBox" />
                            <NumberField source="weightPerBox" />
                            <NumberField source="qtyInTrailBox" />
                            <NumberField source="qty" />
                            <NumberField source="qtyBatch" />
                            <NumberField source="qtyStockedIn" />
                            <NumberField source="qtyPending" />
                            <TextField source="warehouse" />
                            <TextField source="waybill" />
                            <DateField source="estArrivalDate" options={{timeZone: "UTC"}}/>
                            <TextField source="shippingCompany" />
                            <EditButton />
                        </Datagrid>
                </ReferenceManyField>
        </Show>
    </div>
    );
}

PurchaseEmbedded.propTypes = Show.propTypes;

const enhance = compose(
    translate
);

export default enhance(PurchaseEmbedded);