/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    AutocompleteInput,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';

const UserCreate = ({ permissions, ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                source="username"
                autoFocus
                validate={required()}
            />
            <TextInput
                source="email"
                validate={required()}
                type="email"
            />
            <TextInput
                source="password"
                validate={required()}
                type="password"
            />
            <AutocompleteInput
                source="role"
                choices={[
                    { id: '', name: 'None' },
                    { id: 'Admin', name: 'Admin' },
                    { id: 'PM', name: 'PM (Product Manager)' },
                    { id: 'WM', name: 'WM (Warehouse Manager)' },
                    { id: 'PB', name: 'PB (Product Buyer)' },
                    { id: 'FM', name: 'FM (Finance Manager)' },
                ]}
                validate={required()}
            />
        </SimpleForm>
    </Create>
);

export default UserCreate;
