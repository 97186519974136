/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    FormTab,
    SelectInput,
    ReferenceInput,
    TabbedForm,
    DateInput,
    TextInput,
    LongTextInput,
    required,
    Toolbar,
    SaveButton,
} from 'react-admin';

import Aside from './Aside';
import AmountInputsEdit from './AmountInputsEdit';
import autoUpdatePriceEdit from './autoUpdatePriceEdit';
import { dateFormatter, dateParser } from '../lib/date';

const PurchaseField = function({ record }) { 
    return (
        record.purchaseId ? 
                    <ReferenceInput 
                        source="purchaseId" 
                        reference="purchases" 
                        perPage={100} 
                        label="resources.payments.fields.purchase" 
                        validate={required()} 
                    >
                        <SelectInput optionText="name" disabled/>
                    </ReferenceInput>
                    : ""
    );
}

const VendorField = function({ record }) { 
    return (
                    <ReferenceInput 
                        source="vendorId" 
                        reference="vendor" 
                        label="resources.payments.fields.vendor" 
                        validate={required()}  
                        perPage={1000} sort={{ field: 'name', order: 'ASC' }}
                    >
                        <SelectInput optionText="name" disabled/>
                    </ReferenceInput>
    );
}

const PaymentEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label='resources.actions.save_and_show'
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const PaymentEdit = function({ permissions, ...props }) {

    return (
    <Edit aside={<Aside />} {...props}>
        <TabbedForm onChange={autoUpdatePriceEdit} toolbar={<PaymentEditToolbar permissions={permissions} />}>
            <FormTab label="resources.payments.form.summary" path="">

                <PurchaseField />
                <VendorField />
                <AmountInputsEdit  />

                <DateInput
                    source="payment_date"
                    label="resources.payments.fields.payment_date"
                    format={dateFormatter} parse={dateParser}
                />

                <SelectInput source="payment_type" choices={[
                    { id: 'full_payment', name: 'Full Payment (全款)' },
                    { id: 'deposit_payment', name: 'Deposit Payment (首款)' },
                    { id: 'balance_payment', name: 'Balance Payment (尾款)' },
                ]} validate={required()} />
                { (permissions === "Admin" ) &&
                <SelectInput source="status" choices={[
                    { id: 'pending', name: 'resources.payments.tab.pending' },
                    { id: 'preparation', name: 'resources.payments.tab.preparation' },
                    { id: 'preapproved', name: 'resources.payments.tab.preapproved' },
                    { id: 'approved', name: 'resources.payments.tab.approved' },
                    { id: 'paid', name: 'resources.payments.tab.paid' },
                    { id: 'canceled', name: 'resources.payments.tab.canceled' },
                    { id: 'special', name: 'resources.payments.tab.special' },
                ]}  />
                }
                { (permissions !== "Admin" ) &&
                <TextInput source="status" disabled />
                }

                <ReferenceInput source="appUserId" reference="appusers" label="resources.purchases.fields.user" defaultValue={JSON.parse(localStorage.getItem('lbtoken')).value.userId}>
                    <SelectInput optionText="username" />
                </ReferenceInput>
                <DateInput
                    source="product_received_date"
                    label="resources.payments.fields.product_received_date"
                    format={dateFormatter} parse={dateParser}
                />
                <LongTextInput
                    source="note"
                />

            </FormTab>

        </TabbedForm>
    </Edit>
    )
};

PaymentEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default PaymentEdit;
