import React from 'react';

const CurrentSkulistField = function({ record = {} }) { 
	
	const filename = record.skulist ? record.skulist.substr(record.skulist.lastIndexOf('/') + 1) : '';

    return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        <a href={record.skulist} target="_blank" rel="noopener noreferrer">{filename}</a>
    </div>
    )
};

CurrentSkulistField.defaultProps = {
    source: 'skulist',
    addLabel: true,
};

export default CurrentSkulistField;