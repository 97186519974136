import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import {
    DateField,
    EditButton,
    translate,
    NumberField,
    TextField,
    BooleanField,
} from 'react-admin';

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: theme.typography.body1,
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
});

const MobileGrid = ({ classes, ids, data, basePath, translate }) => (
    <div style={{ margin: '1em' }}>
        {ids.map(id => (
            <Card key={id} className={classes.card}>
                <CardHeader
                    title={
                        <div className={classes.cardTitleContent}>
                            <span>
                                {translate('resources.commands.name', 1)}:&nbsp;
                                <TextField
                                    record={data[id]}
                                    source="reference"
                                />
                            </span>
                            <EditButton
                                resource="commands"
                                basePath={basePath}
                                record={data[id]}
                            />
                        </div>
                    }
                />
                <CardContent className={classes.cardContent}>
                    <span className={classes.cardContentRow}>
                        {translate('resources.reviews.fields.date')}:&nbsp;
                        <DateField record={data[id]} source="date" showTime />
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.commands.fields.basket.total')}
                        :&nbsp;
                        <NumberField
                            record={data[id]}
                            source="total"
                            options={{ style: 'currency', currency: 'USD' }}
                            className={classes.total}
                        />
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.commands.fields.status')}:&nbsp;
                        <TextField source="status" record={data[id]} />
                    </span>
                    <span className={classes.cardContentRow}>
                        {translate('resources.commands.fields.returned')}:&nbsp;
                        <BooleanField record={data[id]} source="returned" />
                    </span>
                </CardContent>
            </Card>
        ))}
    </div>
);

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

const enhance = compose(
    withStyles(listStyles),
    translate
);

export default enhance(MobileGrid);