export const companyCategoryOptions = [
    { id: 'factory', name: '产品工厂' },
    { id: 'package', name: '包材厂' },
    { id: 'shipping', name: '货代' },
    { id: 'service', name: '服务商' },
    { id: 'others', name: '其他' }
];

export const PurchaseCategoryOptions = [
    { id: 'factory', name: '产品' },
    { id: 'package', name: '包材' },
    { id: 'shipping', name: '货代' },
    { id: 'service', name: '服务商' },
    { id: 'others', name: '其他' }
];

export const currencyOptions = [
    { id: 'RMB', name: 'RMB' },
    { id: 'USD', name: 'USD' }
];

export const POOptions = [
    { id: 'inProcess', name: 'In Process' },
    { id: 'paid', name: 'Paid' },
    { id: 'shipped', name: 'Shipped'}
];

export const wareHouseOptions = [
    { id: 'amazonFBA', name: 'Amazon FBA' },
    { id: 'rsWarehouse', name: 'RS Warehouse' }
];

export const shipCompanyOptions = [
    { id: 'ups', name: 'UPS' },
    { id: 'dhl_express', name: 'DHL' },
    { id: 'fedex', name: 'FEDEX' }
];
export const stockInReStatuses = [
    { id:'stockedIn', name:'stockedIn'},
    { id:'pending', name:'pending'}
]
export const purchaseFundTypes = [
    {id:'downpayment', name:'首付'},
    {id:'finalpayment', name:'尾款'},
    {id:'fullamount', name:'全款'},
    {id:'other', name:'其他'}
]
export const paymentMethodTypes = [
    {id:'zhifubao', name:'支付宝'},
    {id:'bankcard', name:'银行卡'},
    {id:'creditcard', name:'信用卡'},
    {id:'wechat', name:'微信'},
    {id:'other', name:'其他'}
]
export const orderStatus = [
    {id:'pending', name:'处理中'},
    {id:'finished', name:'完成'},
    {id:'terminated', name:'终止'}
]

export const shipingTypes= [
    {id:'Factory-Agent', name:'工厂->货代'},
    {id:'Factory-AmazonWarehouse', name:'工厂->亚马逊仓库'},
    {id:'Factory-RSWarehouse', name:'工厂->RS仓库'},
    {id:'Agent-RSWarehouse', name:'货代->RS仓库'},
    {id:'Agent-AmazonWarehouse', name:'货代->亚马逊仓库'},
    {id:'Agent-Partner', name:'货代->货代合作仓库'}
]
export const OutStockRequestStatus= [
    {id:'pending', name:'未处理'},
    {id:'stockedOut', name:'已完成'},
    {id:'terminated', name:'已终止'},
]
export const InStockRequestStatus= [
    {id:'pending', name:'未到货'},
    {id:'partStockedIn', name:'部分到货'},
    {id:'fullStockedIn', name:'全部到货'},
    {id:'terminated', name:'已终止'},
]
export const shipingRoutes= [
    {id:'Factory-Agent-LocalWarehouse', name:'工厂->货代->RS仓库'},
    {id:'Factory-Agent-Partner-LocalWarehouse', name:'工厂->货代->合作仓库->RS仓库'},
    {id:'Factory-Agent-AmazonWarehouse', name:'工厂->货代->亚马逊仓库'},
    {id:'Factory-Agent-Partner-AMZWarehouse', name:'工厂->货代->合作仓库->亚马逊仓库'},
    {id:'Factory-AmazonWarehouse', name:'工厂->亚马逊仓库'},
    {id:'Factory-LocalWarehouse', name:'工厂->RS仓库'}
]