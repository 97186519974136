import PackageIcon from '@material-ui/icons/Camera';
import PackageCreate from './PackageCreate';
import PackageEdit from './PackageEdit';
import PackageList from './PackageList';
import PackageShow from './PackageShow';

export default {
    list: PackageList,
    create: PackageCreate,
    edit: PackageEdit,
    show: PackageShow,
    icon: PackageIcon,
};
