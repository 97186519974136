/* eslint react/jsx-key: off */
import React, { Fragment } from 'react';
import {
    Datagrid,
    EditButton,
    List,
    Responsive,
    TextField,
    Pagination,
    TextInput,
    Filter,
    downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MobileGrid from '../layout/MobileGrid';

const exporter = data => {
    let d = new Date();
    let n = d.getFullYear().toString() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate().toString()).slice(-2) + ('0' + d.getHours()).slice(-2) + ('0' + d.getMinutes()).slice(-2);

    const csv = convertToCSV({data})
    const BOM = '\uFEFF'
    downloadCSV(`${BOM} ${csv}`, 'accounts-'+n)
}

const AccountFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput source="code" alwaysOn />
    </Filter>
);

const AccountPagination = props => <Pagination rowsPerPageOptions={[100, 200, 300]} {...props} />

class TabbedDatagrid extends React.Component {

    state = { active: [], blocked: [] };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
    };

    render() {

        const { classes, filterValues, translate, permissions, ...props } = this.props;

        return (

            <Fragment>
                <Tabs
                    fullWidth
                    centered
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    
                        <Tab
                            key="active"
                            label={translate(`resources.accounts.tab.active`)}
                            value="active"
                        />
                    
                        <Tab
                            key="blocked"
                            label={translate(`resources.accounts.tab.blocked`)}
                            value="blocked"
                        />
                </Tabs>
                <Divider />
                <Responsive
                    xsmall={
                        <MobileGrid
                            {...props}
                            ids={this.state[filterValues.status]}
                        />
                    }
                    medium={
                        <div>
                            {filterValues.status === 'active' && (
                                <Datagrid {...props} 
                                    ids={this.state.active} 
                                >
                                        <TextField source="id" />
                                        <TextField source="code" />
                                        <TextField source="name" />
                                        <TextField source="company" />
                                        <TextField source="status" />
                                        <TextField source="address" />
                                        <TextField source="city" />
                                        <TextField source="state" />
                                        <TextField source="country" />
                                        <TextField source="zipcode" />
                                        <EditButton />
                                </Datagrid>
                            )}
                            {filterValues.status === 'blocked' && (
                                <Datagrid {...props} 
                                    ids={this.state.blocked} 
                                >
                                        <TextField source="id" />
                                        <TextField source="code" />
                                        <TextField source="name" />
                                        <TextField source="company" />
                                        <TextField source="status" />
                                        <TextField source="address" />
                                        <TextField source="city" />
                                        <TextField source="state" />
                                        <TextField source="country" />
                                        <TextField source="zipcode" />
                                        <EditButton />
                                </Datagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

const AccountList = ({ classes, permissions, ...props }) => (
    <List
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={100}
        pagination={<AccountPagination />}
        filters={<AccountFilter permissions={permissions} />}
        filterDefaultValues={{ status: 'active' }}
        exporter={exporter}
    >
        <TabbedDatagrid permissions={permissions} />
    </List>
);

export default AccountList;
