import React, { Component }  from 'react';
import compose from 'recompose/compose';
import {translate} from 'react-admin';

class DownloadSkulistField extends Component {

	handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const { record } = this.props;
        window.open(record.skulist, '_blank');    
    }

    getSkulistFile = () => {
        const { record } = this.props;
        if(typeof record.skulist === 'string') {
            return record.skulist.substr(record.skulist.lastIndexOf('.') + 1)
        } else {
            return "";
        }
    }

    render() {
	    return (
	    	this.props.record.skulist === null ? "" : (typeof this.props.record.skulist === "string") ? <a href={this.props.record.skulist} label="download_Skulist" onClick={this.handleClick} color="primary" variant="raised">{this.props.record.skulist.substr(this.props.record.skulist.lastIndexOf('/') + 1)}</a> : "Uploading"
	    )
	}
};

DownloadSkulistField.defaultProps = {
    source: 'skulist',
    addLabel: true,
};

const enhance = compose(
    translate
);

export default enhance(DownloadSkulistField);