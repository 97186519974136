import FinanceIcon from '@material-ui/icons/CreditCard';
import FinanceCreate from './FinanceCreate';
import FinanceEdit from './FinanceEdit';
import FinanceList from './FinanceList';
import FinanceShow from './FinanceShow';

export default {
    list: FinanceList,
    create: FinanceCreate,
    edit: FinanceEdit,
    show: FinanceShow,
    icon: FinanceIcon,
};
