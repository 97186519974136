import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, crudUpdateMany, translate } from 'react-admin';

class PrintedBoxMarkButton extends Component {
    handleClick = () => {
        const { basePath, crudUpdateMany, resource, selectedIds } = this.props;
        crudUpdateMany(resource, selectedIds, { status: 'printed_boxmark' }, basePath);
    };

    render() {
        return (
            <Button label={this.props.translate(`resources.purchases.moveto.printed_boxmark`)} onClick={this.handleClick} />
        );
    }
}

export default translate(connect(undefined, { crudUpdateMany })(PrintedBoxMarkButton));