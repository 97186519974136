/* eslint react/jsx-key: off */
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const PackageReferenceField = props => (
    <div>
        {props.record.packageId ?
            <ReferenceField
                source="packageId"
                reference="packages"
                {...props}
            >
                <TextField source="name" />
            </ReferenceField> : ""
        }
    </div>
);

PackageReferenceField.defaultProps = {
    source: 'packageId',
    addLabel: true,
};

export default PackageReferenceField;
